import Request from "./api.service";
import axios from "axios";

export default class Search {

    cancel;

    static routes(payload) {
        return Request.post(`${Request.base_url}search2`, payload);
    }

    static searchPort(search_query) {
        const CancelToken = axios.CancelToken;
        this.cancel && this.cancel();
        return axios.post(`${Request.base_url}ports`, {search_query}, {
            cancelToken: new CancelToken((c) => {
                this.cancel = c;
            })
        });
    }

    static searchHsnCode(search_query) {
        const CancelToken = axios.CancelToken;
        this.cancel && this.cancel();
        return axios.post(`${Request.base_url}hsn-code`, {search_query}, {
            cancelToken: new CancelToken((c) => {
                this.cancel = c;
            })
        });
    }


}
