import {FETCH_BANK_SUCCESS, FETCH_BANKS} from "../../constants/actionTypes";

export const fetchBanks = () => ({
    type: FETCH_BANKS,
    payload: []
});

export const fetchBankSuccess = (bank) => ({
    type: FETCH_BANK_SUCCESS,
    payload: bank
});

