import React from 'react';
import {Router, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
// import {history} from 'util/history';
// import {store} from "redux/store";
import App from "./containers/App";
import {history} from "./util/history";
import {store} from "./redux/store";

function MainApp() {
    // let store = configureStore();
    return (
        <div className="App">
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route path="/" store={store} component={App}/>
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default MainApp;
