import {FETCH_BANK_SUCCESS, FETCH_BANKS} from "../../constants/actionTypes";

const INIT_STATE = {
    list: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_BANKS:
            return {...state, loading: true};
        case FETCH_BANK_SUCCESS:
            return {...state, list: action.payload, loading: false};
        default:
            return {...state};
    }
}