import React, {Component, Fragment} from "react";
import {Route, Switch} from "react-router-dom";
import TopMenu from "./../TopMenu";
import {Container} from "reactstrap";
import KycDetails from "./KycDetails";

class Kyc extends Component {
    state = {};

    componentDidMount() {

    }

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Switch>
                        <Route path={`${match.url}/upload`} component={KycDetails}/>
                    </Switch>
                </Container>
            </Fragment>
        )
    }
}


export default Kyc;