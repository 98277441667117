import {AUTH_TOKEN_NAME} from '../constants/actionTypes';

export default class Storage {

    static set token(token) {
        localStorage.setItem(AUTH_TOKEN_NAME, token);
    }

    static get token() {
        return localStorage.getItem(AUTH_TOKEN_NAME);
    }

    static setObject(key, object) {
        localStorage.setItem(key, JSON.stringify(object));
    }

    static getObject(item) {
        return JSON.parse(localStorage.getItem(item));
    }

    static remove(key) {
        localStorage.removeItem(key);
    }

    static setFlash(name, message) {
        sessionStorage.removeItem(name);
        sessionStorage.setItem(name, message);
    }

    static getFlash(name) {
        const message = sessionStorage.getItem(name);
        sessionStorage.removeItem(name);
        return message;
    }

    static hasFlash(name) {
        const message = sessionStorage.getItem(name);
        return !!message;
    }

}