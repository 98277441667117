import {FETCH_CONTACTS, FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_FAILURE} from "../../constants/actionTypes";

const INIT_STATE = {
    list: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CONTACTS:
            return {...state, loading: true};
        case FETCH_CONTACTS_SUCCESS:
            return {...state, list: action.payload, loading: false};
        case FETCH_CONTACTS_FAILURE:
            return {...state, loading: false};
        default:
            return {...state};
    }
}
