import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import "react-notifications/lib/notifications.css";
import { AvField, AvForm, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import MemberCard from "../../../components/member/MemberCard";
import ButtonLoader from "../../../util/ButtonLoader";
import { fetchMembers } from "../../../redux/member/actions";
import { connect } from "react-redux";
import MemberModal from "../../../components/member/MemberModal";
import Common from "../../../util/common.service";
import Auth from "../../../util/auth.service";
import { NotificationManager } from "react-notifications";
import Member from "../../../util/member.service";
import Invoice_Configuration from "../../../util/invoice-configuration.service";
import {isEmptyNullUndefined} from "../../../util/util.service";

class InvoiceSetting extends Component {
  state = {
    action: true,
    company_check:false,
    shipper_check: false,
    year_check: false,
    isButtonLoading :false
  };

  componentDidMount() {
    this.getInvoiceSetting();
  }

  getInvoiceSetting = () => {
    Invoice_Configuration.get(Auth.user.company_id)
        .then((response) => {
            if (response !== undefined) {
                if (response.data.status === "success") {
                    this.setState({
                        'company_check': response.data.data.company_prefix == '1' ? true : false,
                        'shipper_check': response.data.data.shipper_prefix == '1' ? true : false,
                        'year_check': response.data.data.year_prefix == '1' ? true : false
                    })
                }
            }
        })
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  }

  handelInvoiceConfigurationSetting = (event,name) => {
    if (event.target.checked) {
      this.setState({[name]:true})  
    } else {
      this.setState({[name]:false})
    }
  }

  onSubmit = () => {
    if (this.state.year_check !== true){
        NotificationManager.error("Please select year prefix check");
        return false;
    }
    if (this.state.company_check == false && this.state.shipper_check == false){
        NotificationManager.error("Please select company prefix or shipper prefix");
        return false;
    }
    this.setState({isButtonLoading:true});
    let data = {
      'company_id': Auth.user.company_id,
      'company': this.state.company_check,
      'shipper': this.state.shipper_check,
      'year': this.state.year_check
    }
    Invoice_Configuration.add(data)
        .then((response) => {
          if (response.data.status === "success") {
            NotificationManager.success(
              "Success message",
              response.data.message
            );
            this.setState({isButtonLoading:false});
          }else{
            NotificationManager.error("Something went wrong");
            this.setState({isButtonLoading:false});
          }
        })
        .catch((error) => {
          console.log(error);
          NotificationManager.error("Error occured");
        });
  }

  render() {
    return (
      <Fragment>
        <div className="add-line-bg">
          <h1>Invoice Setting</h1>
        </div>
        <AvForm onSubmit={this.onSubmit}>
          <div className="itembg">
            <div className="company-all">
              <div className="row h-vgm">
                <div className="col-lg-4 col-12">
                  <AvGroup className="error-t-negative">
                    <AvCheckboxGroup name="checkboxExample" label="Invoice Prefix Configruation">
                        <AvCheckbox label="Company Prefix" value="1" onChange={(event) => this.handelInvoiceConfigurationSetting(event,'company_check')} checked={this.state.company_check}/>
                        <AvCheckbox label="Shipper Prefix" value="1" onChange={(event) => this.handelInvoiceConfigurationSetting(event,'shipper_check')} checked={this.state.shipper_check}/>
                        <AvCheckbox label="Year Prefix" value="1" onChange={(event) => this.handelInvoiceConfigurationSetting(event,'year_check')} checked={this.state.year_check}/>
                    </AvCheckboxGroup>
                  </AvGroup>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="vgm_sub">
                    <button className="btn primary-btn">
                      <ButtonLoader
                        isLoading={this.state.isButtonLoading}
                        label={"Set Configruation"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AvForm>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ members }) => {
  return { members };
};

export default connect(mapStateToProps, { fetchMembers })(InvoiceSetting);
