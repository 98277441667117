import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Common {

    static getAllCountry() {
        return Request.get(`${API_URL}/master/country`);
    }

    static stateByCountry(country_id) {
        return Request.get(`${API_URL}/state-by-country/${country_id}`);
    }

    static cityByState(state_id) {
        return Request.get(`${API_URL}/city-by-state/${state_id}`);
    }

}