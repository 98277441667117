import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Label, Button, button} from "reactstrap";
import Auth from "../../../util/auth.service";
import {NotificationManager} from 'react-notifications';
import ButtonLoader from "../../../util/ButtonLoader";
import {maxUploadFileSize} from "../../../constants/defaultValues";
import PdfIcon from "../../../assets/images/pdf-icon.png";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";
import {isEmptyNullUndefined} from '../../../util/util.service';
import AntdFileUpload from "../../../components/common/AntdFileUpload";

class Register extends Component {

    state = {
        signUpError: '',
        isButtonLoading: false,
        country_id: '',
        country_list: [],
        selected_country: 96,
        companyType: '2',
        gstFile: null,
        gstFileList: null,
        gstFileError: null,
        panCard: null,
        panCardList: null,
        panCardError: null,
        utilityBills: [],
        utilityBillList: [],
        utilityBillError: null
    };

    componentDidMount() {
        this.setState({country_list: this.props.country_list});
    }

    handleType = (event) => {
        this.setState({companyType: event.target.value});
    }

    uploadUtilityBill = () => {
        var imagefile = document.querySelector('#utility_bill');
        let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png', 'xlsx', 'doc', 'docx'];
        var utilityBills = this.state.utilityBills;
        let fileError = null;
        for (let fileKey in imagefile.files) {
            let file = imagefile.files[fileKey];
            if (file && file.name) {
                var parts = file.name.split('.');
                if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                    if (file.size <= maxUploadFileSize) {
                        utilityBills.push(file);
                    } else {
                        fileError = "The uploaded file is exceeding the maximum file size of 5 MB."
                    }
                } else {
                    // fileError = "Please select valid file.";
                }
            }
        }
        this.setState({utilityBills, utilityBillError: fileError}, this.handleUtilityListing);
    };

    handleUtilityListing = () => {
        let utilityBillList = [];
        for (let file in this.state.utilityBills) {
            utilityBillList.push(
                <li key={file}>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={PdfIcon} alt={""}/>{this.state.utilityBills[file].name}
                    </a>
                    <i onClick={() => this.deleteUtilityBill(file)}
                       className="fa fa-times color-changes"/>
                </li>
            );
        }
        this.setState({utilityBillList});
    }

    deleteUtilityBill = (key) => {
        var utilityBills = [];
        for (let doc in this.state.utilityBills) {
            if (doc !== key) {
                utilityBills.push(this.state.utilityBills[doc]);
            }
        }
        this.setState({utilityBills}, this.handleUtilityListing);
    };

    uploadFiles = (fileName, files, fileListing, fileError) => {
        var imagefile = document.querySelector('#' + fileName);
        let fileData = this.state[files];
        let listing = this.state[fileListing];
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxUploadFileSize) {
                    let listing = <li>
                        <a href="" target="_blank" rel="noopener noreferrer">
                            {/*<img src={PdfIcon} alt={""}/>*/}
                            {imagefile.files[0].shortName(30)}
                        </a>
                    </li>;
                    this.setState({
                        [files]: imagefile.files[0] ? imagefile.files[0] : '',
                        [fileListing]: listing,
                        [fileError]: null
                    });
                } else {
                    this.setState({[fileError]: "The uploaded file is exceeding the maximum file size of 5 MB."});
                }
            } else {
                this.setState({[fileError]: "Please select valid file."});
            }
        }
    };

    onSubmit = (event, errors, values) => {
        let gst_file_error = null, utility_bill_error = null, pan_card_error = null;
        if (this.state.utility_bill && !this.state.utility_bill.length) {
            utility_bill_error = "Please Select Utility Bills.";
            errors.push(utility_bill_error);
        }
        if (!this.state.pan_card) {
            pan_card_error = "Please select Pan Card.";
            errors.push(pan_card_error);
        }
        if (!this.state.gst_file) {
            gst_file_error = "Please select Gst File.";
            errors.push(gst_file_error);
        }
        this.setState({utility_bill_error, pan_card_error, gst_file_error});

        if (errors.length === 0) {
            this.setState({isButtonLoading: true});
            let user = Auth.user;
            let data = {
                ...values,
                pan_card: this.state.pan_card,
                gst_file: this.state.gst_file
            };

            if (this.state.utility_bill) {
                for (let file in this.state.utility_bill) {
                    data['utility_bill_' + file] = this.state.utility_bill[file];
                }
            }
            Auth.register(data)
                .then(response => {
                    if (response !== undefined) {
                        if (response.data.status === 'success') {
                            NotificationManager.success('Success message', response.data.message);
                        } else {
                            NotificationManager.error('Error message', response.data.message);
                        }
                        this.registerForm.reset();

                        this.setState({
                            isButtonLoading: false,
                            utilityBills: [],
                            utilityBillList: [],
                            panCard: null,
                            panCardList: null,
                            gstFile: null,
                            gstFileList: null
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.setState({isButtonLoading: false});
                    let msg = !isEmptyNullUndefined(err.response.data.message) ? err.response.data.message : err.response.statusText;
                    NotificationManager.error(msg == 'payload too large' ? 'File size too large' : msg);
                });
        }
    };

    updateState = (fieldName, info,maxFileCount) => {
        let s3Files = info.map((row) => row.s3_file);
        console.log(fieldName, info, s3Files, s3Files.length == maxFileCount);
        this.setState({[fieldName]: s3Files, [fieldName+'_loading'] : s3Files.length == maxFileCount});
    }
    setIsLoading = (buttonLoaderName,isLoading,isDisable) => {
        console.log('asdasd',buttonLoaderName,isLoading,isDisable);
        this.setState({[buttonLoaderName+'_loading']: (isDisable ? isDisable : isLoading), [buttonLoaderName+'_error']: ''});
    }


    render() {

        return (
            <Fragment>
                <AvForm
                    ref={(form) => (this.registerForm = form)}
                    onSubmit={this.onSubmit}
                >

                    <Label className="select-leb">Company Type <span className={'text-danger'}>*</span></Label>
                    <AvRadioGroup
                        value=""
                        inline
                        name="company_type"
                        onClick={this.handleType}
                        required
                        errorMessage="Pick one!"

                    >
                        <AvRadio customInput label="Shipper" value="1"/>
                        <AvRadio customInput label="Forwarder" value="2"/>
                    </AvRadioGroup>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Company Name<span className={'text-danger'}>*</span></Label>
                            <AvField
                                name="company_name"
                                type="text"
                                value=""
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter Company Name",
                                    },
                                }}
                            />
                        </AvGroup>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group select-box">
                                <AvGroup className="error-t-negative">
                                    <label>Registration Type.{this.state.companyType === "1" && <span className={'text-danger'}>*</span>}</label>
                                    <AvField
                                        type="select"
                                        id="reg_type"
                                        name="reg_type"
                                        value=""
                                        validate={{
                                            required: {
                                                value: this.state.companyType === "1",
                                                errorMessage: "Please enter Registration Type",
                                            },
                                        }}
                                    >
                                        <option value="">SELECT</option>
                                        <option value="IEC No">IEC No</option>
                                        <option value="CIN No">CIN No</option>
                                        <option value="PAN No">PAN No</option>
                                        <option value="Aadhaar No">Aadhaar No</option>
                                        <option value="Passport No">Passport No</option>
                                    </AvField>
                                </AvGroup>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="form-group">
                                <AvGroup className="error-t-negative">
                                    <Label>Company Registration No{this.state.companyType === "1" && <span className={'text-danger'}>*</span>}</Label>
                                    <AvField
                                        name="reg_no"
                                        type="text"
                                        value=""
                                        validate={{
                                            required: {
                                                value: this.state.companyType === "1",
                                                errorMessage:
                                                    "Please enter Company Registration No.",
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </div>
                        </div>

                        
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <AvGroup className="error-t-negative">
                                    <Label>Contact person first Name<span className={'text-danger'}>*</span></Label>
                                    <AvField
                                        name="first_name"
                                        type="text"
                                        value=""
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please enter First Name",
                                            },
                                            pattern: {
                                                value: "^[A-Za-z]*$",
                                                errorMessage: "Only letters are allowed",
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <AvGroup className="error-t-negative">
                                    <Label>Contact person last Name<span className={'text-danger'}>*</span></Label>
                                    <AvField
                                        name="last_name"
                                        type="text"
                                        value=""
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Please enter Last Name",
                                            },
                                            pattern: {
                                                value: "^[A-Za-z]*$",
                                                errorMessage: "Only letters are allowed",
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Email Id of Contact person<span className={'text-danger'}>*</span></Label>
                            <AvField
                                name="email"
                                type="text"
                                value=""
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter Email Id",
                                    },
                                    email: {
                                        value: true,
                                        errorMessage: "Please enter Valid Email Id",
                                    },
                                }}
                            />
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Mobile no of Contact person<span className={'text-danger'}>*</span></Label>
                            <AvField
                                name="mobile"
                                type="number"
                                value=""
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please enter Mobile",
                                    },
                                    minLength: {
                                        value: 10,
                                        errorMessage: "Minimum 10 digit required.",
                                    },
                                    maxLength: {
                                        value: 15,
                                        errorMessage: "Maximum 15 digit required.",
                                    },
                                }}
                            />
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Country <span className={'text-danger'}>*</span></Label>
                            <AvField
                                type="select"
                                name="country_id"
                                onChange={this.handleSelectChange}
                                value={this.state.selected_country}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Please select Country",
                                    },
                                }}
                            >
                                <option value="">Select Country</option>
                                {this.props.country_list.map((e, key) => {
                                    return (
                                        <option key={key} value={e.id}>
                                            {e.name}
                                        </option>
                                    );
                                })}
                            </AvField>
                        </AvGroup>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-sm-6 mb-2">
                            <div>
                                <span>Gst File <span className={'text-danger'}>*</span> &nbsp;</span>
                                <AntdFileUpload
                                    customClass={'demo'}
                                    disabled={this.state.gst_file_loading}
                                    label="gst_file"
                                    filePath="gst_file"
                                    allowedFileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                                    maxNoOfFile={1}
                                    maxFileSize={3}
                                    // fileList={[]}
                                    updateParentState={(e) => this.updateState('gst_file', e, 1)}
                                    setIsLoading={(isLoading) => this.setIsLoading('gst_file',isLoading,this.state.gst_file ? this.state.gst_file.length.length == 1 : false)}
                                />
                                {this.state.gst_file_error && <div style={{display:'block'}} className="invalid-feedback">This is required field</div>}
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6 mb-2">
                            <div>
                                <span>PAN Card <span className={'text-danger'}>*</span></span>
                                <AntdFileUpload
                                    customClass={'demo'}
                                    disabled={this.state.pan_card_loading}
                                    label="pan_card"
                                    filePath="pan_card"
                                    allowedFileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                                    maxNoOfFile={1}
                                    maxFileSize={3}
                                    // fileList={[]}
                                    updateParentState={(e) => this.updateState('pan_card', e, 1)}
                                    setIsLoading={(isLoading) => this.setIsLoading('pan_card',isLoading,this.state.gst_file.length == 1)}
                                />
                                {this.state.pan_card_error && <div style={{display:'block'}} className="invalid-feedback">This is required field</div>}
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 mb-2">
                            <div>
                                    <span>Utility Bill any 2(Electricity, Telephone, Premises maintenance bill / L&L copy,
                                        Gas bill).</span>
                                <AntdFileUpload
                                    customClass={'demo'}
                                    disabled={this.state.utility_bill_loading}
                                    label="utility_bill"
                                    filePath="utility_bill"
                                    allowedFileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                                    maxNoOfFile={2}
                                    maxFileSize={3}
                                    // fileList={[]}
                                    updateParentState={(e) => this.updateState('utility_bill', e,2)}
                                    setIsLoading={(isLoading) => this.setIsLoading('utility_bill',isLoading,this.state.gst_file.length == 2)}
                                />
                                {this.state.utility_bill_error && <div style={{display:'block'}} className="invalid-feedback">This is required field</div>}
                            </div>
                        </div>
                    </div>
                    <div className="login-btn">
                        <Button color="primary" disabled={this.state.isButtonLoading}>
                            <ButtonLoader
                                isLoading={this.state.isButtonLoading}
                                label="Register"
                            />
                        </Button>
                    </div>
                </AvForm>
            </Fragment>
        );

    }
}

export default Register;
