import React, {Component, Fragment} from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledDropdown
} from "reactstrap";

const defaultButton = props => (
    <button type="button" {...props} className="-btn">
        {props.children}
    </button>
);

export default class DataTablePagination extends Component {
    constructor(props) {
        super(props);

        this.getSafePage = this.getSafePage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.applyPage = this.applyPage.bind(this);
        this.pageClick = this.pageClick.bind(this);
        this.renderPages = this.renderPages.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.jumpToPageNumber = this.jumpToPageNumber.bind(this);
        this.renderPageJump = this.renderPageJump.bind(this);

        this.state = {
            page: props.page,
            pageSize: this.props.defaultPageSize,
            displayPage: 1
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({page: nextProps.page});
    }

    getSafePage(page) {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }
        return Math.min(Math.max(page, 0), this.props.pages - 1);
    }

    changePageSize(size) {
        this.props.onPageSizeChange(size);
        this.setState({pageSize: size});

    }

    changePage(page) {
        page = this.getSafePage(page);
        this.setState({page});
        if (this.props.page !== page) {
            this.props.onPageChange(page);
        }
    }

    applyPage(e) {
        if (e) {
            e.preventDefault();
        }
        const page = this.state.page;
        this.changePage(page === "" ? this.props.page : page);
    }

    pageClick(pageIndex) {
        this.changePage(pageIndex);
    }

    renderPages() {
        let pageCount = this.props.pages;
        let pageButtons = [];
        let currentPage = this.state.page;
        /* Added by Ravindra to minimise page jump option length */

        let minPage = (currentPage > 5) ? currentPage - 2 : 0;
        let maxPage = (minPage + 10) > pageCount ? pageCount : minPage + 10;
        minPage = (maxPage - minPage) < 10 && maxPage > 10 ? maxPage - 10 : minPage;

        if (typeof this.props.shortPagination !== 'undefined' && this.props.shortPagination === true) {
            minPage = (currentPage > 3) ? currentPage - 2 : 0;
            maxPage = (minPage + 5) > pageCount ? pageCount : minPage + 5;
            minPage = (maxPage - minPage) < 5 && maxPage > 5 ? maxPage - 5 : minPage;
        }

        for (let i = minPage; i < maxPage; i++) {
            let active = this.state.page === i;
            pageButtons.push(
                <PaginationItem key={i} active={active}>
                    <PaginationLink
                        onClick={() => this.pageClick(i)}>
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return pageButtons;
    }

    jumpToPageNumber(e) {
        if (e.target.value) {
            let displayPage = e.target.value;
            let page = e.target.value - 1;
            page = this.getSafePage(page);
            if (this.props.pages - displayPage !== 0) {
                displayPage = this.getSafePage(displayPage);
            }
            displayPage = displayPage === 0 ? 1 : displayPage;
            this.setState({page, displayPage});
            if (this.props.page !== page) {
                this.props.onPageChange(page);
            }
        } else {
            this.setState({displayPage: ''});
        }
    }

    renderPageJump() {
        let pages = this.props.pages;
        let pageNumbers = [];
        for (let i = 0; i < pages; i++) {
            pageNumbers.push(
                <DropdownItem
                    key={i}
                    onClick={() => this.changePage(i)}>
                    {i + 1}
                </DropdownItem>
            );
        }
        return pageNumbers;
    }

    render() {
        let {
            manual,
            data,
            page,
            pages,
            canPrevious,
            canNext,
            pageSizeOptions,
            showPageSizeOptions,
            showPageJump
        } = this.props;

        let displayPage = this.state.displayPage;

        if (!manual) {
            if (this.state.displayPage !== '' && this.state.displayPage - page <= 0) {
                displayPage = page + 1;
                // this.setState({displayPage: page + 1})
            }
            let pageSizeOptions_new = [];
            for (let size of pageSizeOptions) {
                if (data.length >= size) {
                    pageSizeOptions_new.push({size, label: size});
                }
            }
            if (pageSizeOptions_new.length === 0) {
                pageSizeOptions_new.push(pageSizeOptions[0]);
            } else if (pageSizeOptions_new.indexOf(data.length) < 0 && data.length < 200 && !manual) {
                pageSizeOptions_new.push({size: data.length, label: 'All'});
            }
            pageSizeOptions = pageSizeOptions_new;
        } else {
            pageSizeOptions = pageSizeOptions.map(size => {
                return {size, label: size}
            });
        }

        return (
            <Fragment>
                <div className="text-center">
                    {
                        showPageJump &&
                        <div className="float-left pt-2"><span>Page </span>
                            {(
                                typeof this.props.pageJumpType !== 'undefined' &&
                                this.props.pageJumpType === 'input'
                            ) ?
                                <input type="number"
                                       className="react-table-page-jump-input btn btn-outline-primary btn-xs"
                                       value={this.state.displayPage}
                                       max={pages} min={1}
                                       onChange={this.jumpToPageNumber}/>
                                :
                                <UncontrolledDropdown className="d-inline-block">
                                    <DropdownToggle caret color="outline-primary" size="xs">
                                        {this.state.page + 1}
                                    </DropdownToggle>
                                        <DropdownMenu direction="left">
                                            {this.renderPageJump()}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                            }
                            <span> of </span>{pages}</div>
                    }

                    <Pagination className="d-inline-block" size="sm" listClassName="justify-content-center"
                                aria-label="Page navigation example">
                        <PaginationItem className={`${!canPrevious && "disabled"}`}>
                            <PaginationLink
                                className={"prev"}
                                onClick={() => {
                                    if (!canPrevious) return;
                                    this.changePage(page - 1);
                                }}
                                disabled={!canPrevious}>
                                <i className="simple-icon-arrow-left"/>
                            </PaginationLink>
                        </PaginationItem>

                        {this.renderPages()}
                        <PaginationItem className={`${!canNext && "disabled"}`}>
                            <PaginationLink
                                className="next"
                                onClick={() => {
                                    if (!canNext) return;
                                    this.changePage(page + 1);
                                }}
                                disabled={!canNext}>
                                <i className="simple-icon-arrow-right"/>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    {
                        showPageSizeOptions &&
                        <div className="float-right pt-2">
                            <span className="text-muted text-small mr-1">
                              {(
                                  typeof this.props.shortPagination !== 'undefined' &&
                                  this.props.shortPagination === true
                              ) ?
                                  'Items' :
                                  'Items per page'
                              }
                            </span>
                            <UncontrolledDropdown className="d-inline-block">
                                <DropdownToggle caret color="outline-primary" size="xs">
                                    {this.state.pageSize}
                                </DropdownToggle>
                                <DropdownMenu right className="custom-pagination-dropdown">
                                    {pageSizeOptions.map((page, index) => {
                                        return (
                                            <DropdownItem
                                                key={index}
                                                onClick={() => this.changePageSize(page.size)}>
                                                {page.label}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
}
