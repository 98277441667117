import equatorLogo from "../assets/images/equator-logo.jpg" // equator logo
import tglLogo from "../assets/images/tgl-logo.png";
import embassyLogo from "../assets/images/embassy_logo.png";

export const API_URL = process.env.REACT_APP_API_URL;
export const USER_OBJECT_NAME = 'auth-user';
export const LOGIN_URL = `${API_URL}user/login`;
export const LOGOUT_URL = `${API_URL}user/logout`;
export const REGISTER_URL = `${API_URL}user/register`;
export const FORGOT_PASSWORD_URL = `${API_URL}user/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}user/reset-password`;

export const AUTH_TOKEN_NAME = 'auth-token';

export const COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
let companyNameWithLogo = {
    'neptune':equatorLogo,
    'tgl':tglLogo,
    'embassy':embassyLogo,
    'freightgain':equatorLogo,
    'equator':equatorLogo,
};
let company_logo = companyNameWithLogo[COMPANY_NAME.toLowerCase()];

//export const AWS_S3_URL = 'https://fg-equator.s3.us-west-2.amazonaws.com/'; // for equator
//export const AWS_S3_URL = 'https://freightgain.s3.us-west-2.amazonaws.com/'; // for dev
//export const AWS_S3_URL = 'https://tglshipping.s3.us-west-2.amazonaws.com/'; // for tgl
export const AWS_S3_URL = process.env.REACT_APP_AWS_URL;

//export const COMPANY_NAME = 'TGL Shipping';
//export const COMPANY_NAME = 'Equator';

export const COMPANY_LOGO = company_logo;