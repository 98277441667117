export const blStatus = {
    0: "Draft",
    1: "Sent to Vendor",
    2: "User Approval Request",
    3: "Approved by User",
    4: "Rejected by User",
    5: "Issued",
    6: "Return for Correction",
    7: "Revise First Print",
    8: "Rejected by Vendor"
};

const getKeyByValue = (value) => {
    for (let key in blStatus)
        if (blStatus[key] === value)
            return parseInt(key);
    return false;
};

export const DRAFT = getKeyByValue('Draft');
export const SEND_TO_VENDOR = getKeyByValue('Sent to Vendor');
export const USER_APPROVAL_REQUEST = getKeyByValue('User Approval Request');
export const USER_APPROVED = getKeyByValue('Approved by User');
export const USER_REJECTED = getKeyByValue('Rejected by User');
export const ISSUED = getKeyByValue('Issued');
export const RETURN_FOR_CORRECTION = getKeyByValue('Return for Correction');
export const REVISE_FIRST_PRINT = getKeyByValue('Revise First Print');
export const VENDOR_REJECTED = getKeyByValue('Rejected by Vendor');

