import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {setActiveEnquiry} from "../../../redux/enquiry/actions";

class NoBooking extends Component {

    componentDidMount() {
        this.props.setActiveEnquiry(0);
    }

    render() {
        return (
            <Fragment>
                <div className="booking-history-box">
                    {!this.props.enquiries.is_loading ?
                        (this.props.enquiries.list.length ?
                            <div className="booking-history-heading no-booking">
                                Click on a enquiry to view details
                            </div> : <div className="booking-history-heading no-booking">
                                To add a new Enquiry <a color="primary" className="no_booking_btn_click_here" href="/add-enquiry">click here</a>
                            </div>)
                        : <div className="booking-history-heading no-booking">
                            Loading ...
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = ({enquiries}) => {
    return {enquiries};
};

export default connect(
    mapStateToProps,
    {setActiveEnquiry}
)(NoBooking);