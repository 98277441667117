import {FETCH_MEMBER_SUCCESS, FETCH_MEMBERS} from "../../constants/actionTypes";

const INIT_STATE = {
    list: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_MEMBERS:
            return {...state, loading: true};
        case FETCH_MEMBER_SUCCESS:
            return {...state, list: action.payload, loading: false};
        default:
            return {...state};
    }
}