import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Request from "../../../util/api.service";
import { API_URL } from "../../../constants/apiConstants";
import {NotificationManager} from "react-notifications";

class UserAuth extends Component {
  state = {
    country_list: [],
    isLoading: true,
  };

  componentDidMount() {
    Request.get(`${API_URL}master/country`)
      .then((response) => {
          if (response !== undefined) {
              this.setState({country_list: response.data.data, isLoading: false});
          }
      })
      .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
  }

  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <div className="logi-register-all">
                <Switch>
                  <Route path={`${match.url}/register`} component={Register} />
                </Switch>

                <div className="row">
                      <div className="col-sm-6 col-12">
                        <div className="login-section">
                          <h5>Login</h5>
                          <Login history={this.props.history} />
                        </div>
                      </div>
                      <div className="col-sm-6 col-12">
                        <Register
                          history={this.props.history}
                          country_list={this.state.country_list}
                        />
                      </div>
                </div>

                
                
              </div>
            </div>
            {/* <div className="col-sm-8 col-xs-12 offset-sm-2">
                <div className="register-section login-section">
                  
                </div>
            </div> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserAuth;
