import {
    FETCH_BOOKINGS,
    ADD_BOOKINGS,
    RESET_BOOKINGS,
    REFRESH_BOOKINGS,
    SET_ACTIVE_BOOKING,
    UPDATE_BOOKING, REMOVE_BOOKING
} from "../../constants/actionTypes";

export const addBookings = (booking_list) => ({
    type: ADD_BOOKINGS,
    payload: booking_list
});

export const fetchBookings = () => ({
    type: FETCH_BOOKINGS,
    payload: []
});

export const refreshBookings = () => ({
    type: REFRESH_BOOKINGS,
    payload: []
});

export const resetBookings = () => ({
    type: RESET_BOOKINGS,
    payload: []
});

export const setActiveBooking = (booking_id) => ({
    type: SET_ACTIVE_BOOKING,
    payload: booking_id
});

export const removeBooking = (booking_id) => ({
    type: REMOVE_BOOKING,
    payload: booking_id
});

export const updateBooking = (booking) => ({
    type: UPDATE_BOOKING,
    payload: booking
});

