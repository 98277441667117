import React, {Component, Fragment} from "react";
import {Route, withRouter, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import ProfileSideNav from "./ProfileSideNav";
import TopMenu from "./../TopMenu";
import UserProfile from "./user-profile";
import CompanyDetails from "./company";
import BankDetails from "./bank";
import AddressBook from "./address-book";
import {NotificationContainer} from "react-notifications";
import Member from "./member";

class User extends Component {

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Row>
                        <ProfileSideNav/>
                     
                        <Col lg="9" sm="6">
                            <Switch>
                                <Route exact path={`${match.url}/profile`} component={UserProfile}/>
                                <Route exact path={`${match.url}/company`} component={CompanyDetails}/>
                                <Route exact path={`${match.url}/bank`} component={BankDetails}/>
                                <Route exact path={`${match.url}/address-book`} component={AddressBook}/>
                                <Route exact path={`${match.url}/member`} component={Member}/>
                            </Switch>
                            <NotificationContainer/>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }

}

const mapStateToProps = ({menu}) => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(User)
);
