import Auth from "./auth.service";
import {AUTH_TOKEN_NAME, REDIRECT_URL} from "../constants/actionTypes";
import Storage from "./storage.service";
import {history} from "./history";
import {store} from "../redux/store";
import {loginRequired} from "../redux/auth/actions";
import axios from 'axios';

// request interceptor function
export const requestInterceptor = (request) => {
    if (Auth.token) {
        request.headers[AUTH_TOKEN_NAME] = Auth.token;
        request.headers['instance-type'] = 'website'; // dont modify it will be used to for get signature in blade file
    }
    return request
};

// response interceptor function for error
export const errorHandler = (error) => {
    if (axios.isCancel(error)) {
        console.log('Request canceled:', error);
    } else {
        console.log(error);
        if (error.response.status === 401 && error.response.data.status === "token") {
            console.log('ccccc', error);
            Auth.logout();
            Storage.setFlash('error_message', 'Session expired, please login again.');
            Storage.setFlash(REDIRECT_URL, history.location.pathname);
            history.push('/');
            store.dispatch(loginRequired('Your session expired, please login again to view the requested page.'));
        }
    }
};

// response interceptor function for success
export const successHandler = (response) => {
    return response
};