import {FETCH_CONTACTS, FETCH_CONTACTS_FAILURE, FETCH_CONTACTS_SUCCESS} from "../../constants/actionTypes";

export const fetchContacts = () => ({
    type: FETCH_CONTACTS,
    payload: []
});

export const fetchContactsSuccess = (contacts) => ({
    type: FETCH_CONTACTS_SUCCESS,
    payload: contacts
});

export const fetchContactsFailure = () => ({
    type: FETCH_CONTACTS_FAILURE,
    payload: []
});