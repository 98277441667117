import {
    FETCH_ENQUIRIES,
    FETCH_ENQUIRY_SUCCESS,
    REFRESH_ENQUIRIES,
    SET_ACTIVE_ENQUIRY,
    UPDATE_ENQUIRY
} from "../../constants/actionTypes";

export const fetchEnquiries = () => ({
    type: FETCH_ENQUIRIES,
    payload: []
});


export const fetchEnquirySuccess = (enquiry) => ({
    type: FETCH_ENQUIRY_SUCCESS,
    payload: enquiry
});

export const refreshEnquiries = () => ({
    type: REFRESH_ENQUIRIES,
    payload: []
});

export const setActiveEnquiry = (enquiry_id) => ({
    type: SET_ACTIVE_ENQUIRY,
    payload: enquiry_id
});

export const updateEnquiry = (enquiry) => ({
    type: UPDATE_ENQUIRY,
    payload: enquiry
});



