import React, {Component, Fragment} from "react";
import TopMenu from "./TopMenu";
import {connect} from "react-redux";

class Dashboard extends Component {

    componentDidMount() {
        if (!this.props.auth.is_authenticated) {
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <Fragment>
                <TopMenu/>
                <h2 className={"loading-text"}>
                    User Dashboard, coming soon...
                </h2>
            </Fragment>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {}
)(Dashboard);
