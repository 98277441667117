import React, {Component, Fragment} from "react";
import {Button, CardBody} from "reactstrap";
import Auth from "../../util/auth.service";

class BankCard extends Component {

    state = {
        memberDetails: [],
        isLoading: true
    };

    componentDidMount() {
        this.setState({memberDetails: this.props.member, isLoading: false});
    };

    editBank = (id) => {
        this.props.editBank(id);
    };

    removeBank = (id) => {
        this.props.removeBank(id);
    };

    render() {
        let user = Auth.user;
        return !this.state.isLoading ?
            <Fragment>
                <div className="col-md-4">
                    <CardBody>
                        <h4>{this.props.bank.account_no}</h4>
                        <p>{this.props.bank.ifsc_code}</p>
                        <p className="color-chng">{this.props.bank.bank_name}</p>
                        <p className="color-chng">{this.props.bank.branch}</p>
                        <div className="btn-end-card">
                            <Button color="primary" onClick={() => this.editBank(this.props.bank.id)}>
                                <i className="fa fa-edit"/>
                            </Button>
                            &nbsp;

                            <Button color="primary" onClick={() => this.removeBank(this.props.bank.id)}>
                                <i className="fa fa-trash"/>
                            </Button>
                        </div>
                    </CardBody>
                </div>
            </Fragment>
            : <Fragment/>
    }
}

export default BankCard;