import {combineReducers} from 'redux';

import auth from './auth/reducer';
import ports from './ports/reducer';
import bookings from './bookings/reducer';
import contacts from './contacts/reducer';
import enquiries from './enquiry/reducer';
import members from './member/reducer';
import banks from './bank/reducer';

const reducers = combineReducers({
    auth,
    ports,
    bookings,
    contacts,
    enquiries,
    members,
    banks
});

export default reducers;