import React, {Component} from "react";
import {Col} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Select from "react-select";
import Search from "../../../util/search.service";
import moment from "moment";
import {fetchEnquiries, refreshEnquiries} from "../../../redux/enquiry/actions";
import HistoryCard from "../../../components/enquiry/HistoryCard";
import {API_URL} from "../../../constants/apiConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import Request from "../../../util/api.service";
import {isEmptyNullUndefined} from "../../../util/util.service";
import {NotificationManager} from "react-notifications";

class SideNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            enquiries: [],
            selectedPolPort: null,
            selectedPodPort: null,
            selectedShippingLine: null,
            enquiryData: {
                data: [],
                count: 0,
                records: 10,
                loading: true,
                /*filters: {
                    booking_id: null,
                    status: []
                },*/
                sorted: []
            },
            filters: {
                pol_id: null,
                pod_id: null,
                shipline_id: null
            },
            shipLineMessage: null
        }
    }

    componentDidMount() {
        // if (!this.props.enquiries.loaded && !this.props.enquiries.is_loading) {
        //     this.props.refreshEnquiries(); 
            
        // }
        this.getPaginatedEnquiryData();
        this.getShippingLines();
    };

    getShippingLines = () => {
        this.setState({is_loading: true});
        Request.post(API_URL + 'user/shippling_lines').then(response => {
            let shipping_list = response.data.data;
            let shippingLineList = shipping_list.map(ship => {
                return {
                    label: ship.ship_name,
                    un_code: ship.scac_code,
                    value: ship.id,
                    key: ship.id
                };
            });

            if (response.data.data.length == 0 ) {
                this.setState({shipLineMessage: "No result found", is_loading: false});
            } else {
                this.setState({shippingLineList});
            }
        }).catch(error => {
            if(error) {
                NotificationManager.error('Error found');
                console.log(error);
            }
        });
    }

    getPaginatedEnquiryData = (isLoading = true) => {
        console.log('qa',isLoading);
        this.setState({is_loading: isLoading});
        let enquiry = this.state.enquiryData;
        Request.post(API_URL + 'user/enquiry/listing', enquiry).then(response => {
            if (response !== undefined) {
                let enquiryData = this.state.enquiryData;
                let enquiries = enquiryData.data;
                let hasMore = true;
                if (response.data.data.length < enquiryData.records) {
                    hasMore = false
                }

                for (let enquiry of response.data.data) {
                    enquiry['cut_off_date'] = enquiry['cut_off_date'] ? moment(enquiry['cut_off_date']) : null;
                    enquiry['etd_pol'] = enquiry['etd_pol'] ? moment(enquiry['etd_pol']) : null;
                    enquiry['eta_pod'] = enquiry['eta_pod'] ? moment(enquiry['eta_pod']) : null
                    enquiries.push(enquiry);
                }
                enquiryData.data = enquiries;
                enquiryData.count = parseInt(response.data.data.length) + parseInt(enquiryData.count);
                this.setState({
                    enquiries,
                    originalBookings: enquiries,
                    is_loading: false,
                    enquiryData,
                    hasMore
                });
            }else{
                this.setState({is_loading: false});
            }
        }).catch(error => {
            if(error) {
                this.setState({is_loading: false});
                NotificationManager.error('Error found');
                console.log(error);
            }
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (prevProps.enquiries.list.length !== this.props.enquiries.list.length) {
        //     this.setState({enquiries: this.props.enquiries});
        // }
    }

    handleChange = (name, error, selected) => {
        let errorName = null;
        let enquiryData = this.state.enquiryData;
        let filters = this.state.filters;
        if(name === 'selectedPolPort'){
            filters.pol_id = selected.value;
        }

        if(name === 'selectedPodPort'){
            filters.pod_id = selected.value;
        }

        if(name === 'selectedShippingLine'){
            filters.shipline_id = selected.value;
        }


        
        enquiryData.filters = JSON.stringify(filters);
        enquiryData.count = 0;
        enquiryData.data = [];
        this.setState({[name]: selected, [error]: errorName,enquiryData,filters}, this.getPaginatedEnquiryData);
    };
    
    handlePortInputChange = (searchString, portMessage, podPlaceholder) => {
        if (searchString.length >= 3) {
        this.setState({[podPlaceholder]: 'Loading....'});
            searchString = searchString.replace(/\s\s+/g, ' ');
            Search.searchPort(searchString)
                .then((response) => {
                    if (response !== undefined) {
                        let port_list = response.data.data;
                        let portSelectionList = port_list.map(port => {
                            return {
                                label: port.un_code + ' - ' + port.port_name,
                                un_code: port.un_code,
                                value: port.id,
                                key: port.id,
                                country: port.country.name
                            };
                        });

                    if (!response.data.data.length && searchString.length > 3) {
                        this.setState({[portMessage]: "No result found",
                            [podPlaceholder]: (port_list.length === 0 ? 'No record found' : '') });
                    } else {
                        this.setState({portSelectionList,
                            [podPlaceholder]: (port_list.length === 0 ? 'No record found' : '') });
                    }
                }
                }).catch(error => {
                if(error) {
                    NotificationManager.error('Error found');
                    console.log(error);
                }
        });
        }
    };

    
    getEnquiryListHtml = () => {
        // let result_html = [], counter = 0;
        // if (this.props.enquiries.is_loading) {
        //     result_html = (
        //         <h2 className={"loading-text-small"}>
        //             Loading Enquiries...
        //         </h2>
        //     );
        // } else if (this.state.enquiries.list.length === 0) {
        //     result_html = (
        //         <h2 style={{textAlign: 'center', width: '100%'}}>
        //             <br/>
        //           {/*  Sorry, No result found.*/}
        //         </h2>
        //     );
        // } else {
        //     for (let enquiry of this.state.enquiries.list) {
        //         result_html.push(<HistoryCard
        //             enquiryDetails={enquiry}
        //             key={counter++}
        //             isActive={enquiry.id === this.state.enquiries.activeEnquiry}/>);
        //     }
        // }
        // return result_html;



        if (this.state.is_loading) {
            return (
                <h2 className={"loading-text-small"}>
                    Loading Enquiries...
                </h2>
            );
        } else if (this.state.enquiryData.data.length === 0) {
            return (
                <h2 style={{textAlign: 'center', width: '100%'}}>
                    <br/>
                    Sorry, No result found.
                </h2>
            );
        } else {
            return this.state.enquiryData.data.map((booking, index) => {
                return <HistoryCard bookingDetails={booking}
                                    enquiryDetails={booking}
                                    isActive={booking.id === this.state.enquiries.activeEnquiry}
                                    key={index}/>

            });
        }
    };

    fetchMoreData = () => {
        console.log('erer');
        this.getPaginatedEnquiryData(false);
    };
    

    render() {
        return (
            <Col lg="4" sm="5">
                <div className="enquiry-add-btn">
                    <a color="primary" href="/add-enquiry">Add Enquiry</a>
                </div>
                <div className="serach-box-booking ">
                    <div className="search-input-file-cng">
                    <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <Select
                            value={this.state.selectedPolPort}
                            placeholder={isEmptyNullUndefined(this.state.polPlaceholder) ? 'Search Pol' : this.state.polPlaceholder}
                            noOptionsMessage={() => this.state.polPortMessage}
                            onChange={(selectedPort) => this.handleChange('selectedPolPort', 'polPortError', selectedPort)}
                            options={this.state.portSelectionList}
                            onInputChange={(searchString) => this.handlePortInputChange(searchString, 'polPortMessage', 'polPlaceholder')}
                            className="basic-multi-select round-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <Select
                            value={this.state.selectedPodPort}
                            placeholder={isEmptyNullUndefined(this.state.podPlaceholder) ? 'Search Pol' : this.state.podPlaceholder}
                            noOptionsMessage={() => this.state.podPortMessage}
                            onChange={(selected) => this.handleChange('selectedPodPort', 'podPortError', selected)}
                            options={this.state.portSelectionList}
                            onInputChange={(searchString) => this.handlePortInputChange(searchString, 'podPortMessage','podPlaceholder')}
                            className="basic-multi-select round-select"
                            classNamePrefix="select"
                        />
                    </div>
                    </div>
                    <div className="row" style={{marginTop: "10px"}}>
                    <div className="col-lg-12 col-sm-12">
                        <Select
                            value={this.state.selectedShippingLine}
                            placeholder="SHIPPING LINE"
                            noOptionsMessage={() => this.state.shipLineMessage}
                            onChange={(selected) => this.handleChange('selectedShippingLine', 'podPortError', selected)}
                            options={this.state.shippingLineList}
                            className="basic-multi-select round-select"
                            classNamePrefix="select"
                        />
                    </div>
                    </div>
                    </div>

                    <InfiniteScroll
                        dataLength={this.state.enquiryData.data.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        height={400}
                        loader={ this.state.enquiryData.data.length > 0 && <h2 className={"loading-text-small"}>Loading Enquiries...</h2> }
                        endMessage={
                            <p style={{textAlign: "center"}}>
                                <b>{this.state.enquiryData.data.length ? "Yay! You have seen it all" : ""}</b>
                            </p>
                        }
                    >
                        {this.getEnquiryListHtml()}
                    </InfiniteScroll>
                </div>
            </Col>
        );
    }
}

const mapStateToProps = ({enquiries}) => {
    return {enquiries};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchEnquiries, refreshEnquiries}
    )(SideNav)
);