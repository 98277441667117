/* USER */
export const AUTH_TOKEN_NAME = 'auth-token';
export const REDIRECT_URL = 'user_redirect_to';
/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const USER_LOGIN_REQUIRED = 'USER_LOGIN_REQUIRED';
export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const HIDE_AUTH_MODAL = 'HIDE_AUTH_MODAL';
/* AUTH MODAL MODES */
export const SIGN_IN_MODE = 0;
export const SIGN_UP_MODE = 1;
export const FORGOT_PASS_MODE = 2;
/* PORTS */
export const FETCH_PORTS = 'FETCH_PORTS';
export const ADD_PORTS = 'ADD_PORTS';
/* BOOKINGS */
export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const ADD_BOOKINGS = 'ADD_BOOKINGS';
export const REFRESH_BOOKINGS = 'REFRESH_BOOKINGS';
export const RESET_BOOKINGS = 'RESET_BOOKINGS';
export const SET_ACTIVE_BOOKING = 'SET_ACTIVE_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const REMOVE_BOOKING = 'REMOVE_BOOKING';
/* CONTACTS */
export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const REFRESH_CONTACTS = 'REFRESH_CONTACTS';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';
/* ENQUIRY */
export const FETCH_ENQUIRIES = 'FETCH_ENQUIRIES';
export const REFRESH_ENQUIRIES = 'REFRESH_ENQUIRIES';
export const FETCH_ENQUIRY_SUCCESS = 'FETCH_SUCCESS';
export const SET_ACTIVE_ENQUIRY = 'SET_ACTIVE_ENQUIRY';
export const UPDATE_ENQUIRY = 'UPDATE_ENQUIRY';
/* MEMBERS */
export const FETCH_MEMBERS = 'FETCH_MEMBERS';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
/* BANKS */
export const FETCH_BANKS = 'FETCH_BANKS';
export const FETCH_BANK_SUCCESS = 'FETCH_BANK_SUCCESS';
