import React, {Component, Fragment} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import Auth from "../../util/auth.service";
import ButtonLoader from "../../util/ButtonLoader";

class BankModal extends Component {

    state = {
        modalOpen: false,
        isAdmin: 0,
        action: true
    };

    componentDidMount() {
        this.setState({modalOpen: this.props.modalOpen});
    };

    toggleAddModal = () => {
        this.props.toggleAddModal();
    };

    onSubmit = (event, errors, values) => {
        if (errors.length === 0 && !this.props.modalLoading) {
            let user = Auth.user;
            let data = {
                ifsc_code: values.ifsc_code,
                bank_name: values.name,
                branch: values.branch,
                account_no: values.account_no,
                type: values.type,
                company_id: user.company.id
            };
            if (this.props.action) {
                this.props.addBank(data);
            } else {
                this.props.updateBank(data, this.props.bankDetails.id);
            }
        }
    };

    render() {

        return (
            <Fragment>
                <Modal
                    isOpen={this.state.modalOpen}
                    toggle={this.toggleAddModal}
                    wrapClassName="modal-right"
                    backdrop="static"
                    className="add-address-popoup"
                >
                    <ModalHeader toggle={this.toggleAddModal}>
                        {this.state.action ? <h2>Add Bank Account</h2> : <h2>Edit Bank Account</h2>}
                    </ModalHeader>

                    <AvForm onSubmit={this.onSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>IFSC Code</label>
                                            <AvField name="ifsc_code" id="ifsc_code"
                                                     type="text"
                                                     value={this.props.bankDetails ? this.props.bankDetails.ifsc_code : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter IFSC Code.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Bank Name</label>
                                            <AvField name="name" id="name"
                                                     type="text"
                                                     value={this.props.bankDetails ? this.props.bankDetails.bank_name : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Bank Name.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Branch</label>
                                            <AvField name="branch" id="branch"
                                                     type="text"
                                                     value={this.props.bankDetails ? this.props.bankDetails.branch : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Branch.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Bank Account No</label>
                                            <AvField name="account_no" id="account_no"
                                                     type="text"
                                                     value={this.props.bankDetails ? this.props.bankDetails.account_no : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Bank Account No.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Account Type</label>
                                            <AvField type="select"
                                                     name="type"
                                                     value={this.props.bankDetails ? this.props.bankDetails.type : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Bank Account No.'
                                                         }
                                                     }}>
                                                <option value="1">Current</option>
                                                <option value="2">Savings</option>
                                            </AvField>
                                        </AvGroup>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary">
                                <ButtonLoader isLoading={this.props.modalLoading} label={"Submit"}/>
                            </Button>
                            <Button color="secondary" outline onClick={this.toggleAddModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Fragment>
        );
    }

}

export default BankModal;