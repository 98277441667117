import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {FETCH_MEMBERS} from "../../constants/actionTypes";
import {fetchMemberSuccess} from "./actions";
import Member from "../../util/member.service";
import {NotificationManager} from "react-notifications";

const fetchMemberAsync = async () => {
    return await Member.history()
        .then(response => response.data)
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
};

function* fetchMemberFromApi() {
    try {
        const member_data = yield call(fetchMemberAsync);
        if (member_data.status !== 'failed') {
            yield put(fetchMemberSuccess(member_data.data));
        }
    } catch (error) {

    }
}

export function* watchFetchMembers() {
    yield takeEvery(FETCH_MEMBERS, fetchMemberFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchMembers)
    ]);
}