import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Profile {

    static all() {
        return Request.get(`${Request.base_url}user/profile`);
    }

    static update(id, data) {
        data = {...data, '_method': 'PUT'};
        return Request.post(API_URL + 'user/profile/' + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

}
