import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Member {

    static history() {
        return Request.get(`${Request.base_url}user/member`);
    }

    static details(id) {
        return Request.get(`${Request.base_url}user/member/${id}`);
    }

    static add(payload) {
        return Request.post(`${Request.base_url}user/member`, payload);
    }

    static update(id, data) {
        data = {...data, '_method': 'PUT'};
        return Request.post(API_URL + 'user/member/' + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static delete(id) {
        return Request.delete(API_URL + 'user/member/' + id);
    }
}