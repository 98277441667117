import {
    API_URL,
    USER_OBJECT_NAME,
    AUTH_TOKEN_NAME,
    LOGIN_URL,
    REGISTER_URL,
    FORGOT_PASSWORD_URL,
    RESET_PASSWORD_URL
} from "../constants/apiConstants";
import Request from "./api.service";
import Storage from './storage.service';

export default class Auth {

    static get user() {
        return Storage.getObject(USER_OBJECT_NAME);
    }

    static set user(user) {
        Storage.setObject(USER_OBJECT_NAME, user)
    }

    static get check() {
        let token = this.token;
        let user = this.user;
        return !(!token || !user);
    }

    static checkApi() {
        return Request.get(API_URL + 'vendor/user');
    }

    static get id() {
        return 'user_id';
    }

    static get token() {
        return Storage.token;
    }

    static set token(token) {
        Storage.token = token;
    }

    static login(credentials) {
        return Request.post(LOGIN_URL, credentials);
    }

    static logout() {
        Storage.remove(USER_OBJECT_NAME);
        Storage.remove(AUTH_TOKEN_NAME);
        // return Request.post(LOGOUT_URL, {});
    }

    static register(details) {
        return Request.post(REGISTER_URL, details);
    }

    static forgotPassword(email) {
        return Request.post(FORGOT_PASSWORD_URL, {email});
    }

    static resetPassword(payload) {
        return Request.post(RESET_PASSWORD_URL, payload);
    }

}
