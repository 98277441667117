import React, {Component, Fragment} from "react";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {button, Col, Button, UncontrolledAlert} from "reactstrap";
import Select from "react-select";
import {DRY} from "../../../constants/containerTypes";
import {containerTypes} from "../../../constants/containerTypes";
import {API_URL} from "../../../constants/apiConstants";
import collect from "collect.js";
import Request from "../../../util/api.service";
import Search from "../../../util/search.service";
import Auth from "../../../util/auth.service";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ContainerTableRow from "../../../components/bl/ContainerTableRow";
import {slideDown, slideUp} from "./anim";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";
import {customDebounce, isEmptyNullUndefined} from "../../../util/util.service";


class ContainerFilter extends Component {

    state = {
        buttonLoading: false,
        isLoading: true,
        ContainerType: DRY,
        selectedPolPort: null,
        portSelectionList: [],
        vendorList: [],
        selectedVendor: null,
        vendorError: null,
        polPortError: null,
        selectedPodPort: null,
        podPortError: null,
        selectedShipline: null,
        shiplineList: [],
        shiplineError: null,
        commodity_type: null,
        commodity_types: [],
        commodityError: null,
        containerData: [],
        containersDataCopy: [],
        containerSelected: [],
        container_value_20: null,
        container_value_40: null,
        container_value_40hc: null,
        container_count_20: 0,
        container_count_40: 0,
        container_count_40hc: 0,
        responseMessage: null
    }

    componentDidMount() {
        console.log(this.props.location.state);
        this.getVendorList();
        this.getShiplineList();
        this.getCommodity();
        let containerType = this.props.location.state ? this.props.location.state.container_type : '';
        let pol = this.props.location.state ? this.props.location.state.pol : '';
        let pod = this.props.location.state ? this.props.location.state.pod : '';
        let freight_terms = this.props.location.state ? this.props.location.state.freight_terms : '';
        if (!isEmptyNullUndefined(containerType)){
            this.handleContainerTypeChange(containerType);
        }
        if (!isEmptyNullUndefined(pol)){
            this.handlePort(pol.port_name,'polList',true);
        }
        if (!isEmptyNullUndefined(pod)){
            this.handlePort(pod.port_name,'podList',true);
        }
        this.setState({'freight_terms': freight_terms});
    }

    getVendorList = () => {
        Request.get(API_URL + '/vendors').then((response) => {
            if (response !== undefined) {
                let vendorList = [];
                if (response.data.data) {
                    
                    let vendors = response.data.data;
                    for (let vendor of vendors) {
                        let detail = {
                            label: vendor.first_name + " " + vendor.last_name,
                            value: vendor.id,
                            company_id: vendor.company.id,
                            key: vendor.id
                        }
                        vendorList.push(detail);
                    }
                    this.setState({vendorList});
                }
                let vendor = this.props.location.state ? this.props.location.state.vendor : '';
                if (!isEmptyNullUndefined(vendor)){
                    let selectedVendor = vendorList.filter((row)=> row.value == vendor)[0];
                    this.handleChange('selectedVendor', 'vendorError', selectedVendor);
                }
                this.setState({vendorList});
            }
        }).catch(error => {
            NotificationManager.error('Something went wrong');
            console.log(error);
        });
    }


    getShiplineList = () => {
        let shiplineList = [];
        Request.get(API_URL + 'master/ship-line').then((response) => {
            if (response !== undefined) {
                if (response.data.data) {
                    for (let shipline of response.data.data) {
                        shiplineList.push({label: shipline.ship_name, value: shipline.id, key: shipline.id});
                    }
                }
                let shipline = this.props.location.state ? this.props.location.state.shipline : '';
                if (!isEmptyNullUndefined(shipline)){
                    let selectedLine = shiplineList.filter((row)=> row.label == shipline)[0];
                    this.handleChange('selectedShipline', 'shiplineError', selectedLine);
                }
            }
        }).catch(error => {
            NotificationManager.error('Something went wrong');
            console.log(error);
        });
        this.setState({shiplineList});
    };

    getCommodity = () => {
        Request.get(`${API_URL}master/commodity-type`)
            .then(response => {
                if (response !== undefined) {
                    let commodity_types = [];
                    if (response.data.status === 'success') {
                        
                        let filteredCommodity = collect(response.data.data);
                        filteredCommodity = filteredCommodity.where('container_type_id', this.state.ContainerType);

                        filteredCommodity = filteredCommodity.toArray();
                        for (let commodity_type of filteredCommodity) {
                            commodity_types.push({
                                label: commodity_type.title,
                                value: commodity_type.id,
                                key: commodity_type.id,
                                container_type_id: commodity_type.container_type_id
                            });
                        }
                        this.setState({commodity_types, originalCommodityList: response.data.data, isLoading: false});
                    }
                    let commodityId = this.props.location.state ? this.props.location.state.commodity.id : '';
                    this.setState({commodity_types, originalCommodityList: response.data.data, isLoading: false});
                    if (!isEmptyNullUndefined(commodityId)){
                        let selectedType = commodity_types.filter((row)=> row.value == commodityId)[0];
                        this.handleChange('commodity_type', 'commodityError', selectedType);
                    }
                }
            }).catch(error => {
            // NotificationManager.error('Something went wrong');
            console.log(error);
        });
    };

    handlePortInputChange = customDebounce((searchString, portType, podPlaceholder = null) => {
        this.handlePort(searchString,portType,false,podPlaceholder);
    },500);

    handlePort = (searchString, portType, isFirstTime = false,podPlaceholder = null) => {
        searchString = searchString.replace(/\s\s+/g, ' ');
        console.log('bbb',searchString)
        if(searchString.length >= 3){
            if (podPlaceholder){
                this.setState({[podPlaceholder]: 'Loading....'});
            }
            Search.searchPort(searchString).then((response) => {
                if (response) {
                    if (response.data.status === 'success') {
                        let pol = this.props.location.state.pol;
                        if (pol) {
                            pol.label = pol.un_code + ' - ' + pol.port_name;
                            pol.value = pol.id;
                            pol.key = pol.id;
                            pol.country = pol.country.name;
                        }
                        let pod = this.props.location.state.pod;
                        if (pod) {
                            pod.label = pod.un_code + ' - ' + pod.port_name;
                            pod.value = pod.id;
                            pod.key = pod.id;
                            pod.country = pod.country.name;
                        }
                        let requestData = response.data.data;
                        if (podPlaceholder && requestData.length == 0) {
                            this.setState({[podPlaceholder]: 'No record found'});
                        }

                        let records = requestData.map(port => {
                            return {
                                label: port.un_code + ' - ' + port.port_name,
                                un_code: port.un_code,
                                value: port.id,
                                key: port.id,
                                country: port.country.name
                            };
                        });
                        if (isFirstTime) {
                            this.setState({'polList': [pol], 'podList': [pod]});
                        } else {
                            this.setState({[portType]: records});
                        }

                        if (isFirstTime || portType === 'polList') {
                            let selectedPort = isFirstTime ? pol : records.filter((row) => row.value == pol.id)[0];
                            console.log('w', selectedPort, searchString);
                            this.handleChange('selectedPolPort', 'polPortError', selectedPort)
                        }
                        if (isFirstTime || portType === 'podList') {
                            let selectedPort = isFirstTime ? pod : records.filter((row) => row.value == pod.id)[0];
                            console.log('q', selectedPort, pod, searchString);
                            this.handleChange('selectedPodPort', 'podPortError', selectedPort)
                        }
                    } else {
                        NotificationManager.error('handlePort - else');
                    }
                } else {
                    NotificationManager.error('No record found');
                }
            }).catch(error => {
                if (error.message) {
                    NotificationManager.error('handlePort - Something went wrong');
                    console.log('ffff', error);
                }
            });
        }
    };;

    handleChange = (name, error, selected) => {
        let errorName = null;
        this.setState({[name]: selected, [error]: errorName, responseMessage: null}, this.checkLocalCharges);
    };

    containerSelectHandler = (event, container) => {
        let container_value_20 = this.state.container_value_20, container_value_40 = this.state.container_value_40,
            container_value_40hc = this.state.container_value_40hc;
        let container_count_20 = this.state.container_count_20,
            container_count_40 = this.state.container_count_40,
            container_count_40hc = this.state.container_count_40hc;
        if (event.target.checked === true) {
            if (container.container_size === "20") {
                container_value_20 = container.freight_value;
                container_count_20++;
            } else if (container.container_size === "40") {
                container_value_40 = container.freight_value;
                container_count_40++;
            } else if (container.container_size === "40hc") {
                container_value_40hc = container.freight_value;
                container_count_40hc++;
            }

            let containers = this.state.containerSelected;
            containers.push(container.id);
            this.setState({
                containerSelected: containers,
                container_value_20,
                container_value_40,
                container_value_40hc,
                container_count_20,
                container_count_40,
                container_count_40hc
            });
        } else {
            let containers = [];
            if (container.container_size === "20") {
                container_count_20--;
                if (container_count_20 === 0) {
                    container_value_20 = null;
                }
            } else if (container.container_size === "40") {
                container_count_40--;
                if (container_count_40 === 0) {
                    container_value_40 = null;
                }
            } else if (container.container_size === "40hc") {
                container_count_40hc--;
                if (container_count_40hc === 0) {
                    container_value_40hc = null;
                }
            }

            this.state.containerSelected.filter(function (value, index, arr) {
                if (value !== container.id) {
                    containers.push(value);
                }
            });
            this.setState({
                containerSelected: containers,
                container_count_20,
                container_count_40,
                container_count_40hc,
                container_value_20,
                container_value_40,
                container_value_40hc
            });
        }
    };

    toggleExpander = (event) => {
        if (event.target.checked) {
            this.setState(
                {expanded: true},
                () => {
                    if (this.refs.expanderBody) {
                        slideDown(this.refs.expanderBody);
                    }
                }
            );
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {
                    this.setState({expanded: false});
                }
            });
        }
    }

    onSubmit = (event, errors, values) => {
        let polPortError = null,
            podPortError = null,
            shiplineError = null,
            commodityError = null,
            vendorError = null,
            responseMessage = null;
        if (!this.state.selectedPodPort) {
            podPortError = "Please select POD Port.";
            errors.push(podPortError);
        }
        if (!this.state.selectedPolPort) {
            polPortError = "Please select POL Port.";
            errors.push(polPortError);
        }
        if (!this.state.selectedShipline) {
            shiplineError = "Please select Shipline.";
            errors.push(shiplineError);
        }
        if (!this.state.commodity_type) {
            commodityError = "Please select Commodity.";
            errors.push(commodityError);
        }
        if (!this.state.selectedVendor) {
            vendorError = "Please select Vendor.";
            errors.push(vendorError);
        }

        this.setState({podPortError, polPortError, shiplineError, commodityError, vendorError, responseMessage});
        if (errors.length === 0) {
            this.setState({buttonLoading: true});

            let data = {
                user_id: Auth.user.id,
                pol_id: this.state.selectedPolPort.value,
                pod_id: this.state.selectedPodPort.value,
                shipline_id: this.state.selectedShipline.value,
                container_type_id: this.state.ContainerType,
                commodity_type_id: this.state.commodity_type.value,
                pol_agent_company_id: this.state.selectedVendor.company_id,
                ...values
            }

            Request.post(API_URL + 'user/draft_bl', data).then((response) => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        let containerData = [];
                        if (response.data.data) {
                            for (let containerDetail of response.data.data) {
                                let isAvaliable = false;
                                for (let container of containerDetail.containers) {
                                    let charge = 'frt_' + container.container_size;
                                    container.freight_value = container.freight_charges[charge];
                                    if (container.bl_id === null) {
                                        isAvaliable = true;
                                    }
                                }
                                if (isAvaliable) {
                                    if (containerDetail) {
                                        containerData.push(containerDetail);
                                    }
                                }
                            }
                        }
                        this.setState({containerData, containersDataCopy: containerData});
                        if (!containerData.length) {
                            this.setState({
                                responseMessage: <UncontrolledAlert
                                    color="warning"> Containers are not available for this route.</UncontrolledAlert>
                            })
                        }
                    } else {
                        this.setState({
                            responseMessage: <UncontrolledAlert
                                color="warning"> {response.data.message}</UncontrolledAlert>,
                            containerData: [],
                            containersDataCopy: []
                        })
                        // NotificationManager.warning('Warning message', response.data.message);
                    }
                }
                this.setState({buttonLoading: false});
            }).catch(error => {
                NotificationManager.error(error.response.data.message);
                console.log(error);
                this.setState({buttonLoading: false});
            });
        }
    };

    handleContainerTypeChange = (eventTargetValue) => {
        let commodity_types = [];
        let filteredCommodity = collect(this.state.originalCommodityList);

        filteredCommodity = filteredCommodity.where('container_type_id', parseInt(eventTargetValue));
        filteredCommodity = filteredCommodity.toArray();

        for (let commodity_type of filteredCommodity) {
            commodity_types.push({
                label: commodity_type.title,
                value: commodity_type.id,
                key: commodity_type.id,
                container_type_id: commodity_type.container_type_id
            });
        }

        this.setState({
            ContainerType: parseInt(eventTargetValue),
            commodity_types,
            commodity_type: null,
            responseMessage: null
        });
    };

    startDraftBl = () => {
        localStorage.setItem('containers', this.state.containerSelected);
        this.props.history.push('/bl/draft-bl-new');
    };

    handleBookingNoFilter = (event) => {
        if (this.state.containersDataCopy) {
            let containerData = collect(this.state.containersDataCopy);
            if (event.target.value !== "") {
                containerData = containerData.filter((value, key) => value.book_id.includes(event.target.value.toUpperCase()));
            }
            containerData = containerData.toArray();
            this.setState({containerData});
        }
    };

    render() {
        let containerType = [];
        for (let type in containerTypes) {
                containerType.push(<option value={type}>{containerTypes[type]}</option>);
        }

        return (
            <Fragment>
                <div className="heading-bill-lading">
                    <h1>Create New BL</h1>
                </div>
                {this.state.responseMessage}
                <div className="main-div-bl">
                    <div className="container">
                        <div className="row">
                            <Col lg="12" sm="12">
                                <div className="bil-new-form">
                                    <AvForm onSubmit={this.onSubmit}>
                                        <div className="row">
                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <AvField label="Container Type" type="select"
                                                             onChange={this.handleContainerTypeChange}
                                                             name="container_type" value={this.state.ContainerType}>
                                                        {containerType}
                                                    </AvField>
                                                </div>
                                            </Col>

                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <label>Vendor</label>
                                                    <Select
                                                        className="home-select"
                                                        name="shipline"
                                                        value={this.state.selectedVendor}
                                                        placeholder="Select Vendor"
                                                        noOptionsMessage={() => "No record found"}
                                                        onChange={(selected) => this.handleChange('selectedVendor', 'vendorError', selected)}
                                                        options={this.state.vendorList}
                                                    />
                                                    <CustomFeedback>{this.state.vendorError}</CustomFeedback>
                                                </div>
                                            </Col>

                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <label>Port of Loading</label>
                                                    <Select
                                                        className="local-charges-select"
                                                        value={this.state.selectedPolPort}
                                                        placeholder={"Search Port"}
                                                        noOptionsMessage={() => !isEmptyNullUndefined(this.state.polPlaceholder) ? this.state.polPlaceholder : "Start typing port name"}
                                                        onChange={(selectedPort) => this.handleChange('selectedPolPort', 'polPortError', selectedPort)}
                                                        options={this.state.portSelectionList}
                                                        onInputChange={(val,action)=> {
                                                            if (val) {
                                                                this.handlePortInputChange(val, 'polList','polPlaceholder')
                                                            }
                                                        }}
                                                    />
                                                    <CustomFeedback>{this.state.polPortError}</CustomFeedback>
                                                </div>
                                            </Col>

                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <label>Port of Discharge</label>
                                                    <Select
                                                        className="local-charges-select"
                                                        value={this.state.selectedPodPort}
                                                        placeholder="Search Port"
                                                        noOptionsMessage={!isEmptyNullUndefined(this.state.podPlaceholder) ? this.state.podPlaceholder : "Start typing port name"}
                                                        onChange={(selected) => this.handleChange('selectedPodPort', 'podPortError', selected)}
                                                        options={this.state.portSelectionList}
                                                        onInputChange={(val,action)=> {
                                                            if (val) {
                                                                this.handlePortInputChange(val, 'podList', 'podPlaceholder')
                                                            }
                                                        }}
                                                    />
                                                    <CustomFeedback>{this.state.podPortError}</CustomFeedback>
                                                </div>
                                            </Col>
                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <label>ShipLine</label>
                                                    <Select
                                                        className="home-select"
                                                        name="shipline"
                                                        value={this.state.selectedShipline}
                                                        placeholder="Select shipline"
                                                        noOptionsMessage={() => "No record found"}
                                                        onChange={(selected) => this.handleChange('selectedShipline', 'shiplineError', selected)}
                                                        options={this.state.shiplineList}
                                                    />
                                                    <CustomFeedback>{this.state.shiplineError}</CustomFeedback>
                                                </div>
                                            </Col>
                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <label>Commodity</label>
                                                    <Select
                                                        className="home-select"
                                                        value={this.state.commodity_type}
                                                        maxMenuHeight={110}
                                                        name="commodity_type"
                                                        isDisabled={this.state.ContainerType && this.state.ContainerType === 3}
                                                        onChange={(selected) => this.handleChange('commodity_type', 'commodityError', selected)}
                                                        options={this.state.commodity_types}
                                                        placeholder="Commodity"
                                                        noOptionsMessage={() => "No record found"}
                                                    />
                                                    <CustomFeedback>{this.state.commodityError}</CustomFeedback>
                                                </div>
                                            </Col>

                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <AvField label="Freight terms" type="select"
                                                             name="freight_terms" value={this.state.freight_terms}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please select Freight terms'
                                                                 }
                                                             }}>
                                                        <option value="">SELECT</option>
                                                        <option value="1">Prepaid</option>
                                                        <option value="2">Collect</option>
                                                    </AvField>
                                                </div>
                                            </Col>

                                            <Col lg="3" sm="3">
                                                <div className="fied-bl">
                                                    <button disabled={this.state.buttonLoading} className="btn btn-primary">
                                                        {this.state.buttonLoading ? 'Loading...' : 'Submit'}
                                                    </button>
                                                </div>
                                            </Col>
                                        </div>
                                    </AvForm>
                                </div>
                            </Col>
                        </div>
                        <br/>

                        <AvForm>
                            <AvField type="text" name="booking_id" id="booking_id" value=""
                                     placeholder="Search by Booking Number"
                                     onChange={(event) => this.handleBookingNoFilter(event)}/>
                        </AvForm>

                        {this.state.containerData.length ?
                            <div className="row bil-new-form">
                                <div className="slide-table">
                                    <table
                                        className="uk-table uk-table-hover  uk-table-middle uk-table-divider">
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th className="uk-table-shrink">Shipment Ref No</th>
                                            <th>POL</th>
                                            <th>POD</th>
                                            <th>ETD at POL</th>
                                            <th>ETA at POD</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!this.state.containerData
                                            ? <tr>
                                                <td colSpan={6} className="uk-text-center"><em
                                                    className="uk-text-muted">Loading...</em></td>
                                            </tr>
                                            : this.state.containerData.map((container, index) =>
                                                <ContainerTableRow expanded={this.state.expanded}
                                                                   key={index}
                                                                   containerSelected={this.state.containerSelected}
                                                                   containerSelectHandler={this.containerSelectHandler}
                                                                   index={index + 1}
                                                                   toggleExpander={this.toggleExpander}
                                                                   container={container}
                                                                   container_value_20={this.state.container_value_20}
                                                                   container_value_40={this.state.container_value_40}
                                                                   container_value_40hc={this.state.container_value_40hc}
                                                />
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {this.state.containerSelected.length ?
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="start-bl-btn">
                                                <Button color="primary" onClick={this.startDraftBl} size="sm">Start
                                                    Draft BL</Button>
                                            </div>
                                        </div>
                                    </div> : null}

                            </div> : null}


                    </div>

                </div>
                <NotificationContainer/>
            </Fragment>
        );
    }

}

export default ContainerFilter;