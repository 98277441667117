export const equipmentTypes = {
    0: "Open Top",
    1: "Flat Rack"
};

const getKeyByValue = (value) => {
    for (let key in equipmentTypes)
        if (equipmentTypes[key] === value)
            return parseInt(key);
    return false;
};

export const OPENTOP = getKeyByValue('Open Top');
export const FLATRACK = getKeyByValue('Flat Rack');