import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Invoice_Configuration {

    static add(payload) {
        return Request.post(`${Request.base_url}user/invoice-setting`, payload);
    }

    static get(id) {
        return Request.get(`${Request.base_url}user/invoice-setting/${id}`);
    }
}