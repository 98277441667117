import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import portSagas from './ports/saga';
import bookingSagas from './bookings/saga';
import contactSagas from './contacts/saga';
import enquirySagas from './enquiry/saga';
import memberSagas from './member/saga';
import bankSagas from './bank/saga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        portSagas(),
        bookingSagas(),
        contactSagas(),
        enquirySagas(),
        memberSagas(),
        bankSagas()
    ]);
}