import React, {Component, Fragment} from "react";

class CustomFeedback extends Component {
    render() {
        return (
            <Fragment>
                {this.props.children ?
                    <div className={"invalid-feedback-user invalid-feedback"}>{this.props.children}</div>
                    : ""}
            </Fragment>
        );
    }
}

export default CustomFeedback;