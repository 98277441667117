import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import {Row, Card, CardTitle, Label, Button, UncontrolledAlert} from "reactstrap";
import {NavLink} from "react-router-dom";
import {loginUser, loginUserFailure, loginUserSuccess, logoutUser, showAuthModal} from "../../../redux/auth/actions";
import {connect} from "react-redux";
import Storage from "../../../util/storage.service";
import {REDIRECT_URL} from "../../../constants/actionTypes";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {NotificationContainer, NotificationManager} from "react-notifications";

class ForgotPassword extends Component {

    state = {};

    componentDidMount() {

    }

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            let data = {
                ...values
            }
            Request.post(API_URL + '/user/forgot-password', data).then((response) => {
                if (response !== undefined) {
                    if (response.data.status === "success") {
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        NotificationManager.error('Error message', response.data.message);
                    }
                }
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
            this.props.loginUser(values, this.props.history);
        }
    };

    render() {

        return (
            <Fragment>
                <div className="col-sm-6 col-lg-6 border-div">
                    <div className="register-section login-section">
                        <AvForm onSubmit={this.onSubmit}>
                            <AvGroup className="error-t-negative">
                                <Label>Email</Label>
                                <AvField name="email" type="text"
                                         value={this.state.email}
                                         onChange={this.handleChange}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Email Id'
                                             }
                                         }}/>
                            </AvGroup>

                            <div className="login-btn">
                                <Button color="primary">
                                    Submit
                                </Button>
                                <NavLink to={`/login`}>
                                    Login
                                </NavLink>
                            </div>
                        </AvForm>
                    </div>
                </div>
                <NotificationContainer/>
            </Fragment>
        )
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUser, loginUserFailure}
)(ForgotPassword);

