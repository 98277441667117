import {
    ADD_BOOKINGS,
    FETCH_BOOKINGS,
    REMOVE_BOOKING,
    RESET_BOOKINGS,
    UPDATE_BOOKING,
    SET_ACTIVE_BOOKING
} from "../../constants/actionTypes";
import moment from "moment";
import {bookingStatus} from "../../constants/bookingStatus";

const INIT_STATE = {
    is_loading: false,
    loaded: false,
    list: [],
    activeBooking: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_BOOKINGS:
            return {...state, is_loading: true};
        case ADD_BOOKINGS:
            action.payload = action.payload.map((booking) => {
                booking['booking_status_name'] = (typeof bookingStatus[booking['booking_status']] !== 'undefined') ? bookingStatus[booking['booking_status']] : '';
                try {
                    booking['cro_details'] = (booking.cro_details) ? JSON.parse(booking.cro_details) : false;
                } catch (e) {
                    booking['cro_details'] = false;
                }
                booking['cut_off_date'] = moment(booking['cut_off_date']);
                booking['etd_pol'] = moment(booking['etd_pol']);
                booking['eta_pod'] = booking['eta_pod'] ? moment(booking['eta_pod']) : null;
                return booking;
            });
            return {...state, loaded: true, is_loading: false, list: action.payload};
        case RESET_BOOKINGS:
            return {...INIT_STATE};
        case SET_ACTIVE_BOOKING:
            return {...state, activeBooking: parseInt(action.payload)};
        case UPDATE_BOOKING:
            let bookingList = state.list;
            for (let booking in bookingList) {
                if (parseInt(action.payload.id) === parseInt(bookingList[booking].id)) {
                    let bookingDetails = action.payload;
                    bookingDetails['booking_status_name'] = (typeof bookingStatus[bookingDetails['booking_status']] !== 'undefined') ? bookingStatus[bookingDetails['booking_status']] : '';
                    try {
                        bookingDetails['cro_details'] = (bookingDetails.cro_details) ? JSON.parse(bookingDetails.cro_details) : false;
                    } catch (e) {
                        bookingDetails['cro_details'] = false;
                    }
                    bookingDetails['cut_off_date'] = moment(bookingDetails['cut_off_date']);
                    bookingDetails['etd_pol'] = moment(bookingDetails['etd_pol']);
                    bookingDetails['eta_pod'] = moment(bookingDetails['eta_pod']);
                    bookingList[booking] = bookingDetails;
                }
            }
            return {...state, list: bookingList};
        case REMOVE_BOOKING:
            let Bookings = state.list, BookingDetails = [];
            for (let booking of Bookings) {
                if (booking.id !== action.payload) {
                    BookingDetails.push(booking);
                }
            }
            return {...state, list: BookingDetails};
        default:
            return {...state};
    }
}
