import React, {Component} from "react";
import {Link} from "react-router-dom";

class Error extends Component {

    render() {
        return <h2>
            Oops, page not found. <br/>
            <Link to="/">Click here to Go to home page</Link>
        </h2>
    }

}

export default Error;