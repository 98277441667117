export const hazClasses = {
    1: "Class 1 - Explosives",
    2: "Class 2 - Flammable Gases",
    3: "Class 3 - Flammable Liquids",
    4: "Class 4 - Flammable solids",
    5: "Class 5 - Oxidizing",
    6: "Class 6 - Toxic & Infectious",
    7: "Class 7 - Radioactive",
    8: "Class 8 - Corrosives",
    9: "Class 9 - Miscellaneous",
};
