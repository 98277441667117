import React, { Fragment } from 'react';
import { Row, Container, Modal, Col, ModalHeader, ModalBody, button } from "reactstrap";
import { Link } from "react-router-dom";
import bokingconfirmboat from '../../../assets/images/boking-confirm-boat.png';
import shipLineIcon from '../../../assets/images/shipline-icon.png';

class NewViewDeal extends React.Component {

    state = {
        openPriceBreakdownModal: false,
    };

    openPriceBreakdownModal = () => {
        this.setState({ openPriceBreakdownModal: true });
    };

    toggleopenPriceBreakdownModal = () => {
        this.setState({
            openPriceBreakdownModal: !this.state.openPriceBreakdownModal
        });
    };

    render() {

        return (
            <Fragment>
                <div className="view-deal-full-page">
                    <Container>
                        <Row>
                            <Col sm="12" lg="12">
                                <div className="confirm-booking">
                                    <Link to={"/"}>
                                        <i className="fa fa-long-arrow-left" aria-hidden="true" /> Back to Search Results
                                    </Link>
                                    <h1>Confirm Booking</h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="new-change-confirm-booking-full">
                    <Container>
                        <Row>
                            <Col lg="9" sm="9" className="center-div-col">
                                <div className="new-change-confirm-booking">
                                    <Row>
                                        <Col lg="8" sm="8" className="center-div-border">
                                            <div className="new-change-confirm-booking-inn">
                                                <Row>
                                                    <Col lg="5" sm="5">
                                                        <div className="confirm-booking-inn-box">
                                                            <h5>Departure</h5>
                                                            <span>India</span>
                                                            <h1>INNSA - Nhava Sheva (J-awaharlal Nehru)</h1>
                                                            <b><span>etd</span> 26 Nov 2019,  Wednesday</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="2" sm="2" className="div-p-no">
                                                        <div className="confirm-booking-inn-box-img">
                                                            <img src={bokingconfirmboat} alt="" className="img-fluid" />
                                                            <p>10 Days</p>
                                                        </div>
                                                    </Col>
                                                    <Col lg="5" sm="5">
                                                        <div className="confirm-booking-inn-box">
                                                            <h5>Arrival</h5>
                                                            <span>China</span>
                                                            <h1>VNDAD - Da Nang</h1>
                                                            <b><span>eta</span> 26 Nov 2019,  Wednesday</b>
                                                        </div>
                                                    </Col>
                                                    <Col lg="12" sm="12">
                                                        <div className="new-box-port">
                                                            <span><img src={shipLineIcon} alt="" className="img-fluid" /></span>
                                                            <p>China Ocean Shipping (Group) Company (COSCO)</p>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>

                                        <Col lg="4" sm="4" className="responsive-pd">
                                            <div className="total-charges-newconfirm-box">
                                                <h1>Total Charges</h1>
                                                <ul>
                                                    <li>
                                                        <p>International Charges</p>
                                                        <b><span>USD ($)</span> 1851</b>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-plus" aria-hidden="true" />
                                                    </li>
                                                    <li>
                                                        <p>Local Charges</p>
                                                        <b><span>INR (&#8377;)</span>1,90,367</b>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="view-price-breakdown-newconfirm-box">
                                                <h2><span>inr (&#8377;)</span>  3,23,639</h2>
                                                <span>* Provisional Exchange Rate. Basis actual may vary. {/* <img src={PriceDropIcon} alt="" className="img-fluid" />*/}</span>
                                                <button onClick={this.openPriceBreakdownModal} >View Price Breakdown</button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" sm="12">
                                            <div className="confirm-booking-new-table">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>Container</th>
                                                        <th>Qty</th>
                                                        <th>Cargo Weight per Container ( kg )</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>20 ft</td>
                                                        <td><div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-minus"/>
                                                                    </button>
                                                                </span>
                                                            <input type="text" name="quant[1]" className="form-control input-number" min="1" max="10" value="1" />
                                                            <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-plus"/>
                                                                    </button>
                                                                </span>
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" value="58,900" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>40 ft</td>
                                                        <td><div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-minus"/>
                                                                    </button>
                                                                </span>
                                                            <input type="text" name="quant[1]" className="form-control input-number" min="1" max="10" value="1" />
                                                            <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-plus"/>
                                                                    </button>
                                                                </span>
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" value="" placeholder="Enter Weight" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>40 ft HC</td>
                                                        <td><div className="counter-bg">
                                                                <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-minus"/>
                                                                    </button>
                                                                </span>
                                                            <input type="text" name="quant[1]" className="form-control input-number" min="1" max="10" value="1" />
                                                            <span className="input-group-btn">
                                                                    <button type="button" className="btn btn-default btn-number">
                                                                        <i className="fa fa-plus"/>
                                                                    </button>
                                                                </span>
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" name="" value="" placeholder="Enter Weight" />
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="cargo-line-hazardous-btn">
                                        <Row>
                                            <Col lg="12" sm="12">
                                                <div className="new-cargo-contains-hazardous">
                                                    <label className="container-lable">My Cargo Contains Hazardous / Dangerous Goods
                                                        <input type="checkbox" />
                                                        <span className="checkmark"/>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg="4" sm="4">
                                                <div className="new-class-tr-div">
                                                    <p>Class</p>
                                                    <ul className="class-btn-new">
                                                        <li>
                                                            <div className="container1 active">
                                                                <span className="triangle"/>
                                                                <span>3</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="container1">
                                                                <span className="triangle"/>
                                                                <span>6</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="container1">
                                                                <span className="triangle"/>
                                                                <span>8</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="container1">
                                                                <span className="triangle"/>
                                                                <span>9</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>

                                            <Col lg="4" sm="4">
                                                <div className="new-class-tr-div-packing-group">
                                                    <p>Packing Group</p>
                                                    <ul >
                                                        <li>
                                                            <label className="container-lable">Group 1
                                                                <input type="checkbox" />
                                                                <span className="checkmark"/>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="container-lable">Group 2
                                                                <input type="checkbox" />
                                                                <span className="checkmark"/>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label className="container-lable">Group 3
                                                                <input type="checkbox" />
                                                                <span className="checkmark"/>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="new-un-number">
                                                    <p>{/*<img src={PriceDropIcon} alt="" className="img-fluid" />*/}UN Number</p>
                                                    <input type="text" name="" value="1502" placeholder="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="new-cargo-reefer-commodity">
                                        <Row>
                                            <Col lg="12" sm="12">
                                                <div className="heading-commodity">
                                                    <h5>My Cargo is Reefer Commodity</h5>
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="commodity-box-one">
                                                    <p>Temperature ( ºC )</p>
                                                    <input type="text" name="" placeholder="Temperature" value="" />
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="commodity-box-one">
                                                    <p>Humidity ( % )</p>
                                                    <input type="text" name="" placeholder="Humidity" value="" />
                                                </div>
                                            </Col>
                                            <Col lg="4" sm="4">
                                                <div className="commodity-box-one align-change-ventilation">
                                                    <p>Ventilation ( CBM / Per Hour )</p>
                                                    <input type="text" name="" placeholder="Enter Number" value="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                            <div className="new-describe-your-cargo-full">
                                        <Row>
                                            <Col lg="6" sm="6">
                                              <div className="exporter-box">
                                              <p>Exporter</p>
                                              <input type="text" name="" placeholder="Exporter Name" value="" />
                                              <span>+</span>
                                              </div>
                                            </Col>
                                            <Col lg="6" sm="6">
                                                <div className="new-class-tr-div-packing-group btnfull-freight-terms">
                                                    <p>Freight terms</p>
                                                    <ul >
                                                    <ul >
                                                        <li>
                                                            <label className="container-lable">Prepaid
                                                                <input type="checkbox" />
                                                                <span className="checkmark"/>
                                                                </label>
                                                        </li>
                                                        <li>
                                                            <label className="container-lable">Collect
                                                                <input type="checkbox" />
                                                                <span className="checkmark"/>
                                                            </label>
                                                        </li>
                                                    
                                                    </ul>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                        </div>
                                    <div className="new-describe-your-cargo-full">
                                        <Row>
                                            <Col lg="8" sm="8">
                                                <div className="new-describe-your-box">
                                                    <label>Please describe your cargo or commodity<span> ( Max. 50 Characters )</span></label>
                                                    <textarea name="comment" placeholder="Enter cargo or commodity" form="usrform"/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" sm="12">
                                                <div className="note-approvals">
                                                    <b>**Below commodities may need special handling and approvals.</b>
                                                    <p>Soya, Raw Cotton, Batteries, Tobacco, Incense and Match Sticks, Oils, Toys, Scrap, Personal Effects</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row>
                                        <Col lg="12" sm="12">
                                            <div className="btn-confirm-new">
                                                <p>* Please note above THC displayed is only basis XYZ Terminal, for other Terminals the same may vary.</p>
                                                <ul>
                                                    <li>
                                                        <button>Raise Enquiry</button>
                                                    </li>
                                                    <li>
                                                        <button>Confirm Booking</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                            <div className="your-booking-quote-full">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="bookin-gquote-heading">
                                            <h1>Your Booking Quote</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <div className="booking-includes">
                                            <div className="incliude-heading">
                                                <h3>Includes</h3>
                                            </div>
                                            <ul>
                                                <li>
                                                    <span>Sea Freight Charges.</span>
                                                </li>
                                                <li>
                                                    <span>Bill of Lading Fees, AMS, ENS, POD DO Fees etc.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <div className="booking-includes booking-excludes">
                                            <div className="incliude-heading">
                                                <h3>Excludes</h3>
                                            </div>
                                            <ul>
                                                <li>
                                                    <span>Hazardous / Dangerous Goods</span>
                                                </li>
                                                <li>
                                                    <span>BL Telex Fees.</span>
                                                </li>
                                                <li>
                                                    <span>Cargo Insurance</span>
                                                </li>
                                                <li>
                                                    <span>All detention/storage/demurrage charges at port(s).</span>
                                                </li>
                                                <li>
                                                    <span>Any Customs Clearence charges (at POL &amp; POD).</span>
                                                </li>
                                                <li>
                                                    <span>All Ex-Works Logistics Charges at POL on FOT Terms.</span>
                                                </li>

                                                <li>
                                                    <span>POL &amp; POD related charges (THC, LOLO etc.).</span>
                                                </li>
                                                <li>
                                                    <span>POD Local Charges (Chargeable to Consignee).</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="bookin-gquote-heading">
                                            <h1>Terms &amp; Conditions</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="term-conditions">
                                            <h3>Quote Validity :</h3>
                                            <p>The quotation is valid only for this transaction. It cannot be utilized for any other transaction or vessel date for the same port pair.</p>
                                            <h3>Payment :</h3>
                                            <p>On completion of the transaction, all charges to be paid in full on receipt of the invoice. The bill of lading would be released only thereafter. All prices are in USD and local currency as mentioned in the quotes should be credited to Freightgain Vendor account in full without deduction of any sender or receiver bank charges.</p>
                                            <h3>All-Inclusive Rates :</h3>
                                            <p>All published rates are all-inclusive in nature for the services that have been chosen, otherwise, they are subject to additional cost for services additionally required.</p>
                                            <h3> Vessel and Cut-Off Schedule :</h3>
                                            <p className="term-conditions-text">1. Vessel ETD, ETA are indicative in nature and subject to change without notice.</p>
                                            <p>2. Cut-Off Dates to be met by the shipper as published unless otherwise communicated.</p>
                                            <h3> Booking Terms :</h3>
                                            <p className="term-conditions-text"># Please stuff cargo only as per the weight mentioned in the quotation. Please do not exceed payload.</p>
                                            <p className="term-conditions-text">1. Hazardous / Dangerous Goods booking confirmation and loading is subject to approval by carrier.</p>
                                            <p className="term-conditions-text"> # For Hazardous Cargo, Please ensure to paste stickers on all sides of the containers as per IMD guidelines.</p>
                                            <p className="term-conditions-text"># For Perishable Cargo, Please ensure receiver is ready in all aspects to take delivery on arrival. Booking Party responsible for cargo rejections / abandonment.</p>
                                            <p className="term-conditions-text"># Palletized cargo or where wooden packing material is used needs to be fumigated as per Importing Country Specific Requirements.</p>
                                            <p className="term-conditions-text">2. All "Port" (POL &amp; POD) quotations are subject to local charges, government taxes &amp; documentation fees as prevalent.</p>
                                            <p className="term-conditions-text"> 3. Please cover adequate insurance, bill of lading that covers protection of the cargo only at sea.</p>
                                            <p className="term-conditions-text">4. All "Door" quotations are subject to local government taxes.</p>
                                            <p className="term-conditions-text">5. Container detention free time is not mandatory and needs to be applied for and approved by the shipping line for each booking individually.</p>
                                            <p>2. Cut-Off Dates to be met by the shipper as published unless otherwise communicated.</p>
                                            <h3> Booking Terms :</h3>
                                            <p className="term-conditions-text">1. Only one promo code can be applied per transaction and will only be valid for the booking being made.</p>
                                            <p className="term-conditions-text">2. Promotional discounts will be applied in their entirety to the Invoice and no refund for unused amounts will be made.</p>
                                            <p>3. Promo code will be considered as used even if the Shipmentis canceled post-booking.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
                <Modal className="price-breackdown-popup" isOpen={this.state.openPriceBreakdownModal}
                       toggle={this.toggleopenPriceBreakdownModal}>
                    <ModalHeader toggle={this.toggleopenPriceBreakdownModal}>
                        Price Breakdown ( India Specific )
                    </ModalHeader>
                    <ModalBody className="price-breakdown-table">
                        <Container>
                            <div className="pol-full-line">


                                <Row>
                                    <Col lg="4" sm="4">
                                        <div className="pol-view-price">
                                            <b>Pol</b>
                                            <p>Innsa - nhava sheva (jawaharlal nehru)</p>
                                        </div>
                                    </Col>
                                    <Col lg="4" sm="4">
                                        <div className="pol-view-price">
                                            <b>Pod</b>
                                            <p>Innsa - nhava sheva (jawaharlal nehru)</p>
                                        </div>
                                    </Col>
                                    <Col lg="2" sm="2">
                                        <div className="pol-view-price">
                                            <b>Date</b>
                                            <p>05-02-2020</p>
                                        </div>
                                    </Col>
                                    <Col lg="2" sm="2">
                                        <div className="pol-view-price">
                                            <b>Etd</b>
                                            <p>09-02-2020</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <table>
                            <thead>
                            <tr>
                                <th>Heads </th>
                                <th>Basis</th>
                                <th>Type</th>
                                <th>Qty</th>
                                <th>Per container Price</th>
                                <th>usd</th>
                                <th>inr</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="border-none">
                                <td colSpan="4" className="local-area color-one">
                                    <a href={"#"}>international</a>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Freight Charges</b></td>
                                <td>Per container</td>
                                <td>20</td>
                                <td>1</td>
                                <td>$104</td>
                                <td> $ 104</td>
                                <td className="change-td"/>
                            </tr>
                            <tr>
                                <td/>
                                <td>Per container</td>
                                <td>40</td>
                                <td>3</td>
                                <td>$ 192</td>
                                <td>$ 576</td>
                                <td className="change-td"/>
                            </tr>
                            <tr>
                                <td/>
                                <td>Per container</td>
                                <td>40hc</td>
                                <td>2</td>
                                <td>$ 599</td>
                                <td>$ 1198</td>
                                <td className="change-td"/>
                            </tr>

                            <tr>
                                <td><b>Hazardous basic</b></td>
                                <td>Per container</td>
                                <td>20</td>
                                <td>1</td>
                                <td>$ 100</td>
                                <td>$ 100</td>
                                <td className="change-td"/>
                            </tr>
                            <tr>
                                <td/>
                                <td>Per container</td>
                                <td>40</td>
                                <td>3</td>
                                <td>$ 120</td>
                                <td>$ 360</td>
                                <td className="change-td"/>
                            </tr>
                            <tr>
                                <td/>
                                <td>Per container</td>
                                <td>40hc</td>
                                <td>2</td>
                                <td>$ 150</td>
                                <td>$ 300</td>
                                <td className="change-td"/>
                            </tr>

                            <tr>
                                <td><b>ENS / AMS</b></td>
                                <td>Per Document</td>
                                <td/>
                                <td/>
                                <td/>
                                <td>$ 30</td>
                                <td/>
                            </tr>

                            <tr className="border-none">
                                <td colSpan="6" className="local-area">
                                    <a href={"#"}>Local</a>
                                </td>
                            </tr>
                            <tr>
                                <td><b>THC</b></td>
                                <td colSpan="5">Per container</td>
                                <td className="change-td">(₹) 500</td>
                            </tr>
                            <tr>
                                <td><b>Miscellaneous</b></td>
                                <td colSpan="5">Per container</td>
                                <td className="change-td">(₹) 2,500</td>
                            </tr>
                            <tr>
                                <td><b>Platform fee</b></td>
                                <td colSpan="5">Per container</td>
                                <td className="change-td">(₹) 1,000</td>
                            </tr>
                            <tr>
                                <td><b>Documentation fee</b></td>
                                <td colSpan="5">Per Document</td>
                                <td className="change-td">(₹) 999</td>
                            </tr>
                            <tr>
                                <td><b className="price-breakdown-table">Total Charges</b></td>
                                <td className="change-td total-charge-last-one" colSpan="5">USD ($) 1851</td>
                                <td className="change-td total-charge-last">INR (₹)1,90,367</td>
                            </tr>
                            </tbody>

                        </table>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default NewViewDeal;