import React, {Component} from "react";
import {Route, withRouter, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import SearchResults from './search/result-list';
import ViewDeal from './search/view-deal';
import NewViewDeal from './search/new-view-deal'
import Dashboard from "./dashboard";
import "react-table/react-table.css";
import User from './user';
import Booking from "./booking";
import Error from "./error";
import ResetPassword from "./reset-password";
import Auth from "../../util/auth.service";
import Storage from "../../util/storage.service";
import Enquiry from "./enquiry";
import {REDIRECT_URL} from "../../constants/actionTypes";
import Kyc from "./kyc";
import UserAuth from "./auth";
import ForgotPassword from "./auth/ForgotPassword";
import AddEnquiry from "./enquiry/AddEnquiry";
import Bl from "./bl";
import BlApproved from "./bl/bl-approved";
import BlAmend from "./bl/bl-amend";
import setPassword from "./reset-password/setPassword";
import CheckListDetails from "./check-list/CheckListDetails"


const PrivateRoute = ({component: Component, ...rest}) => {
    /* if (typeof rest.store === 'undefined') {
        throw new Error("store prop missing for a private route");
    }
    let store = rest.store;
    delete rest.store; */
    return <Route {...rest} render={props => {
        if (Auth.check) {
            return <Component {...props} />
        } else {
            // store.dispatch(loginUserFailure('Please login to visit the requested page'));
            // store.dispatch(showAuthModal());
            Storage.setFlash(REDIRECT_URL, props.location.pathname);
            return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        }
    }}/>;
};

class MainApp extends Component {

    render() {
        const {match} = this.props;
        return (
            <div id="app-container">
                <Switch>
                    <Route exact path={`${match.url}`} component={Dashboard}/>
                    <Route path={`${match.url}search`} component={SearchResults}/>
                    <Route path={`${match.url}view-deal`} component={ViewDeal}/>
                    <Route path={`${match.url}new-view-deal`} component={NewViewDeal}/>
                    <PrivateRoute path={`${match.url}dashboard`} component={Dashboard}/>
                    <PrivateRoute path={`${match.url}user`} component={User}/>
                    <PrivateRoute path={`${match.url}booking`} component={Booking}/>
                    <PrivateRoute path={`${match.url}bl`} component={Bl}/>
                    <PrivateRoute path={`${match.url}enquiry`} component={Enquiry}/>
                    <Route exact path={`${match.url}add-enquiry`} component={AddEnquiry}/>
                    <Route exact path={`${match.url}add-enquiry/:id`} component={AddEnquiry}/>
                    <PrivateRoute path={`${match.url}kyc`} component={Kyc}/>
                    <Route path={`${match.url}reset-password/:token`} component={ResetPassword}/>
                    <Route path={`${match.url}bl-approved/:token`} component={BlApproved}/>
                    <Route path={`${match.url}bl-amend/:token`} component={BlAmend}/>
                    <Route path={`${match.url}set-password/:token`} component={setPassword}/>
                    <Route exact path={`${match.url}login`} component={UserAuth}/>
                    <Route exact path={`${match.url}forgot-password`} component={ForgotPassword}/>
                    <Route path={`${match.url}check-list/:id`} component={CheckListDetails}/>
                    <Route path={`${match.url}error`} component={Error}/>
                    {/*<Redirect to="/error"/>*/}
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = ({menu}) => {
    return {};
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(MainApp)
);

