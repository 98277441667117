import React, {Component, Fragment} from "react";
import {Route, withRouter, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import TopMenu from "./../TopMenu";
import SideNav from "./SideNav";
import {Col, Container, Row} from "reactstrap";
import {fetchBookings, refreshBookings} from "../../../redux/bookings/actions";
import BookingDetails from "./BookingDetails";
import NoBooking from "./NoBooking";
import VgmForm from "./vgm/VgmForm";
import {showAuthModal} from "../../../redux/auth/actions";
import Enquiry from "../../desktop/booking/enquiry";
import BookingRequest from "../../../util/booking.service";
import Form13 from "./Form13";

class Booking extends Component {

    state = {
        is_loading: true,
        bookingList: [],
    };

    componentDidMount() {
        if (!this.props.bookings.loaded && this.props.auth.is_authenticated) {
            this.props.fetchBookings();
        }
    };

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Row>
                        <div className="col-sm-12 col-lg-12">
                            <div className="commodities-div"><p>Commodities may need special handling and approvals
                                are -Soya, Raw Cotton, Batteries, Tobacco, Incense and Match Sticks, Oils, Toys,
                                Scrap, Personal Effects</p></div>
                        </div>
                        <SideNav/>
                        <Col lg="8" sm="7">
                            <Switch>
                                <Route path={`${match.url}/history`} component={NoBooking}/>
                                <Route path={`${match.url}/:id/view`} component={BookingDetails}/>
                                <Route exact path={`${match.url}/:id/form13`} component={Form13}/>
                                <Route path={`${match.url}/:id/vgm`} component={VgmForm}/>
                               {/* <Route path={`${match.url}/enquiry`} component={Enquiry}/>*/}
                                <Redirect to={`${match.url}/history`}/>
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = ({auth, bookings}) => {
    return {auth, bookings};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchBookings, refreshBookings, showAuthModal}
    )(Booking)
);
