import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import {NavLink, withRouter} from "react-router-dom";
// import pendingIcon from "../../../assets/images/pending-icon.png";
import warningIconCircle from "../../../assets/images/warning-icon-circle.png";
import Auth from "../../../util/auth.service";

class TopMenu extends Component {

    render() {
        let bookingHistoryClass = "", profileClass = "", enquiryHistoryClass = "", kycClass = "", AddEnquiry="", BlClass="";
        const {match} = this.props;
        if (match.path === '/booking') {
            bookingHistoryClass = "active";
        } else if (match.path === '/user') {
            profileClass = "active";
        } else if (match.path === '/enquiry/history') {
            enquiryHistoryClass = "active";
        } else if (match.path === '/kyc') {
            kycClass = "active";
        }else if (match.path === '/add-enquiry') {
            AddEnquiry = "active";
        }else if (match.path === '/bl') {
            BlClass = "active";
        }

        let user = Auth.user;
        return (
            <div className="history-heading">
                {/*<Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="my-account-heading">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">My Account</li>
                                    </ol>
                                </nav>
                            </div>
                        </Col>
                    </Row>
                </Container>*/}
                <Container>
                    <Row>
                        <Col lg="12" sm="12">
                            <div className="history-nev">
                                <ul>
                                    <li>
                                        <NavLink to="/dashboard">Dashboard</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/booking/history" className={bookingHistoryClass}>
                                            Booking History
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/enquiry/history" className={enquiryHistoryClass}>
                                            Enquiries
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bl/history" className={BlClass}>
                                            Bill of Lading
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/user/profile" className={profileClass}>
                                            Profile {/*<img src={warningIconCircle} className="img-fluid pending-icon"
                                                         title="Profile is incomplete" alt="Pending"/>*/}
                                        </NavLink>
                                    </li>
                                    <li>
                                        {user && user.company.account_details && user.company.account_details.gst ?
                                            <NavLink to="/kyc/upload" className={kycClass}>
                                                KYC
                                            </NavLink> :
                                        <NavLink to="/kyc/upload" className={kycClass}>
                                            KYC <img src={warningIconCircle} className="img-fluid pending-icon"
                                                     title="KYC Pending" alt="Pending"/>
                                        </NavLink>}
                                    </li>
                                    {/*<li>
                                        <Link to="#">Support</Link>
                                    </li>
                                    <li>
                                        <Link to="#">T&C</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Report Issue</Link>
                                    </li>*/}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(TopMenu);