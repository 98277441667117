export const bookingStatus = {
    1: "Rate Quotation",
    2: "CRO Requested",
    3: "CRO Received",
    4: "VGM Updated",
    5: "BL Issued",
    6: "VGM Pending",
    7: "User Accepted",
    8: "User Rejected",
    9: "User Approval Pending",
    10: "Booking Cancelled"
};

const getKeyByValue = (value) => {
    for (let key in bookingStatus)
        if (bookingStatus[key] === value)
            return parseInt(key);
    return false;
};

export const BOOKING_REQUESTED = getKeyByValue('Booking Requested');
export const CRO_REQUESTED = getKeyByValue('CRO Requested');
export const CRO_RECEIVED = getKeyByValue('CRO Received');
export const UPDATE_VGM = getKeyByValue('VGM Updated');
export const BL_ISSUED = getKeyByValue('BL Issued');
export const PENDING_VGM = getKeyByValue('VGM Pending');
export const USER_ACCEPTED = getKeyByValue('User Accepted');
export const USER_REJECTED = getKeyByValue('User Rejected');
export const USER_APPROVAL_PENDING = getKeyByValue('User Approval Pending');
export const BOOKING_CANCELLED = getKeyByValue('Booking Cancelled');
