// eslint-disable-next-line no-extend-native
import moment from "moment";

String.prototype.truncateFileName = String.prototype.truncateFileName ||
    function (length = 25) {
        let fileNameParts = this.split('.');
        return (this.length > length) ? this.substr(0, length - 1) + '...' + fileNameParts[fileNameParts.length - 1] : this;
    };

// eslint-disable-next-line no-extend-native
String.prototype.ordinalSuffix = String.prototype.ordinalSuffix ||
    function () {
        let i = parseInt(this);
        var j = i % 10, k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    };

// eslint-disable-next-line no-extend-native
Number.prototype.ordinalSuffix = Number.prototype.ordinalSuffix ||
    function () {
        let i = this;
        var j = i % 10, k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    };

File.prototype.shortName = File.prototype.shortName ||
    function (length = 25) {
        let fileName = this.name;
        let fileNameParts = fileName.split('.');
        return (fileName.length > length) ? fileName.substr(0, length - 1) + '...' + fileNameParts[fileNameParts.length - 1] : fileName;
    };

export const isMobile = function () {
    return window.innerWidth <= 1024;
    /*var check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;*/
};

export const ordinalSuffix = (i) => {
    var j = i % 10, k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
};

export const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true
    );
};

export const encrypt = (string) => {
    return btoa(string);
};

export const decrypt = (string) => {
    return atob(string);
};

export const copyToClipboard = str => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
};

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const generateQueryUrl = (data) => {
    let searchQuery = [];
    for (let param in data) {
        searchQuery.push(param + '=' + data[param]);
    }
    searchQuery = searchQuery.join('&');
    return '?' + searchQuery;
};

export const getTotalFreight = (size, freight_charges) => {
    let total_freight = 0;
    let keys = ['frt_', 'caf_', 'pss_', 'gri_', 'misc_', 'ams_'];
    for (let key of keys) {
        total_freight = total_freight + +freight_charges[key + size];
    }
    return total_freight;
};

export const formatRoute = (route) => {
    route['etd_pol'] = moment(route['etd_pol'], 'YYYY-MM-DD');
    route['eta_pod'] = moment(route['eta_pod'], 'YYYY-MM-DD');
    route['cut_off_date'] = moment(route['cut_off_date'], 'YYYY-MM-DD');
    route['freight_charges_20'] = getTotalFreight('20', route.freight_charges);
    route['freight_charges_40'] = getTotalFreight('40', route.freight_charges);
    route['freight_charges_40hc'] = getTotalFreight('40hc', route.freight_charges);
    route['carrier_name'] = route.ship_line.carrier_name;
    route['ship_line'] = route.ship_line.ship_name;

    return route;
};

export const formatRouteEnquiry = (route) => {
    route['freight_charges_20'] = getTotalFreight('20', route.freight_charges);
    route['freight_charges_40'] = getTotalFreight('40', route.freight_charges);
    route['freight_charges_40hc'] = getTotalFreight('40hc', route.freight_charges);
    route['carrier_name'] = route.ship_line.carrier_name;
    route['ship_line'] = route.ship_line.ship_name;

    return route;
};

export const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const replacePlaceHolders = (string, replace = {}) => {
    if(replace){
        for (let placeholder in replace) {
            string = string.replace(`:${placeholder}`, replace[placeholder]);
        }
    }
    return string;
};

export const getBookingNote = (bookingStatus, replace = {}) => {
    const bookingNotes = {
        1: "Booking has been requested",
        2: "CRO has been requested to the shipping line",
        3: "CRO Received for the booking, please update VGM for all containers",
        4: "VGM uploaded, From 13 can be started now.",
        5: "BL has been issued for the container",
        6: "VGM Pending for :pending_vgm container(s)",
        7: "Booking conversion approved by you",
        8: "",//"User Rejected",
        9: "",//"User Approval Pending"
        10:"Booking has been cancelled"
    };
    return replacePlaceHolders(bookingNotes[bookingStatus], replace);
};

export const getBLNote = (blStatus, replace = {}) => {
    const blNotes = {
        0: "BL Draft is in progress, you can submit it to vendor for further processing",
        1: "BL Draft has been sent to vendor for further processing",
        2: "You need to approve the BL Draft processed by your vendor",
        3: "BL Draft has been approved by you and sent to vendor for further processing",
        4: "BL Draft has been rejected by you and has been sent to vendor for amendment.",
        5: "BL has been issued for the booking"
    };
    return replacePlaceHolders(blNotes[blStatus], replace);
};
export const isEmptyNullUndefined = (val) =>
  typeof val == "undefined"
    ? true
    : typeof val == "number"
    ? false
    : typeof val == "string"
    ? val.trim() === ""
    : typeof val == "object"
    ? Object.is(val, null)
      ? true
      : Object.keys(val).length === 0
    : false;
export const customDebounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};
export const getFormattedDate = (val, datePattern)=> {
    // let datePattern = "ddd, DD MMM'YY";
            console.log(val, datePattern);
    if (!isEmptyNullUndefined(val) && val !== 'null') {
        if (moment.isMoment(val)) {
            if (val.isValid()) {
                let formattedDate = (val).format(datePattern);
                if (!isEmptyNullUndefined(formattedDate)) {
                    return formattedDate;
                }
            } else {
                return 'N/A';
            }
        } else {
            let formattedDate = moment(val).format(datePattern);
            console.log(formattedDate);
            if (!isEmptyNullUndefined(formattedDate)) {
                return formattedDate;
            }
            return 'N/A';
        }
    }
    return 'N/A';
}
