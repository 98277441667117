
export const enquiryTypes =  {
    1: "Project/Overdimensional",
    2: "Rates Unavailable",
    3: "Hazardous Rates",
    4: "Bulk Enquiry",
};

const getKeyByValue = (value) => {
    for (let key in enquiryTypes)
        if (enquiryTypes[key] === value)
            return parseInt(key);
    return false;
};

export const OVERDIMENSIONAL = getKeyByValue('Project/Overdimensional');
export const RATES_UNAVAILABLE = getKeyByValue('Rates Unavailable');
export const BULK_ENQUIRY = getKeyByValue('Bulk Enquiry');
export const HAZARDOUS_RATES = getKeyByValue('Hazardous Rates');