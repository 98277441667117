import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {REDIRECT_URL, SIGN_IN_MODE} from "../../../constants/actionTypes";
import Storage from "../../../util/storage.service";
import {showAuthModal} from "../../../redux/auth/actions";
import Enquiry from "../../../util/enquiry.service";
import {HAZARDOUS_RATES, OVERDIMENSIONAL, RATES_UNAVAILABLE} from "../../../constants/enquiryTypes";
import {containerTypes, REFRIGERATED} from "../../../constants/containerTypes";
import {button, Col, Container, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import {
    BOOKING_REQUESTED,
    bookingStatus,
    USER_ACCEPTED,
    USER_APPROVAL_PENDING,
    USER_REJECTED
} from "../../../constants/bookingStatus";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import moment from "moment";
import {formatRouteEnquiry} from "../../../util/util.service";
import {AvForm} from "availity-reactstrap-validation";
import Auth from "../../../util/auth.service";
import Contact from "../../../util/contact.service";
import {SHIPPER} from "../../../constants/contactType";
import {setActiveEnquiry, updateEnquiry} from "../../../redux/enquiry/actions";
import {equipmentTypes} from "../../../constants/equipmentTypes";
import Request from "../../../util/api.service";
import {API_URL, AWS_S3_URL} from "../../../constants/apiConstants";
import Select from "react-select";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";
import error from "../error";

class EnquiryDetails extends Component {

    state = {
        loading: true,
        enquiryDetails: [],
        openPriceBreakdownModal: false,
        booking_status: null,
        shipperList: [],
        shipperError: null,
        promoCodeCharges: 0,
        originalPromoCodeCharges: 0,
        title: "",
        promoCode: "",
        show_prices: 0,
        show_promocode: 0,
        platform_fees:0
    };

    componentDidMount() {
        if (!this.props.auth.is_authenticated) {
            this.props.showAuthModal(SIGN_IN_MODE);
            Storage.setFlash(REDIRECT_URL, '/enquiry/' + this.props.match.params.id + '/view');
            this.props.history.push("/");
        } else {
            Storage.getFlash(REDIRECT_URL);
            this.getEnquiryDetails();
            this.getShipperList();
        }
        this.props.setActiveEnquiry(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.setActiveEnquiry(this.props.match.params.id);
            this.getEnquiryDetails();
        }
    }

    getShipperList = () => {
        if (this.props.auth.is_authenticated) {
            Contact.getContactByType(SHIPPER).then(response => {
                let shipperList = [];
                for (let exporter of response.data.data) {
                    shipperList.push({label: exporter.company_name, value: exporter.id, key: exporter.id});
                }
                this.setState({shipperList});
            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    toggleopenPriceBreakdownModal = () => {
        this.setState({
            openPriceBreakdownModal: !this.state.openPriceBreakdownModal
        });
    };

    handlePromoCode = (event) => {
        this.setState({title: event.target.value});
    };

    removePromoCode = () => {
        this.setState({
                promoCodeCharges: 0,
                basic_freight: this.state.enquiryDetails.booking.basic_freight,
                total_freight: this.state.enquiryDetails.booking.total_amount.total_amount_usd_sales ? this.state.enquiryDetails.booking.total_amount.total_amount_usd_sales : 'NA',
                invalidPromoCode: null,
                promoCode: "",
                originalPromoCodeCharges: 0,
                title: ""
            },
            this.calculateCharges
        );
        NotificationManager.success('Success message', 'Promo code has been removed.');
    };

    checkPromoCode = () => {
        let invalidPromoCode = null;
        this.setState({
            promoCodeCharges: 0,
            invalidPromoCode: null,
            promoCode: "",
            originalPromoCodeCharges: 0,
            discountType: null,
            discountValue: 0
        });
        if (this.state.title !== "") {

            let data = {
                title: this.state.title,
                vendor_company_id: this.state.enquiryDetails.booking.pol_agent_company_id,
                shipline_id: this.state.enquiryDetails.booking.shipline_id,
                pol_id: this.state.enquiryDetails.booking.pol_id,
                pod_id: this.state.enquiryDetails.booking.pod_id
            }

            Request.post(API_URL + '/promo-code', data)
                .then(response => {
                    if (response.data.status === "failed") {
                        invalidPromoCode = response.data.message;
                        this.setState({
                            invalidPromoCode,
                            promoCodeCharges: 0,
                            originalPromoCodeCharges: 0
                        }, this.calculateCharges);
                    } else {
                        this.setState({
                            discountType: response.data.data.discount_type,
                            discountValue: response.data.data.discount_value,
                            promoCode: response.data.data.id
                        }, this.calculatePromoCodeCharges);
                        NotificationManager.success('Success message', response.data.message);
                    }
                }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
            this.setState({invalidPromoCode});
        } else {
            this.calculateCharges();
        }
    }

    calculatePromoCodeCharges = () => {
        let promoCodeCharges = 0, originalPromoCodeCharges = 0;
        if (this.state.discountType) {
            let feright_charges = this.state.basic_freight;

            if (this.state.discountType === 1) {
                promoCodeCharges = this.state.discountValue;
            } else {
                promoCodeCharges = (feright_charges * this.state.discountValue) / 100;
            }
            originalPromoCodeCharges = promoCodeCharges;

        }

        this.setState({promoCodeCharges, originalPromoCodeCharges}, this.calculateCharges);
    };

    calculateCharges = () => {
        let promoCodeCharges = this.state.originalPromoCodeCharges;
        let basic_freight = this.state.basic_freight;
        if (basic_freight < this.state.originalPromoCodeCharges) {
            promoCodeCharges = basic_freight;
            basic_freight = 0;
        } else {
            basic_freight = basic_freight - this.state.originalPromoCodeCharges;
        }
        let total_freight = parseFloat(this.state.total_freight) - parseFloat(promoCodeCharges);
        this.setState({promoCodeCharges, total_freight, basic_freight});
    };

    getEnquiryDetails = () => {
        this.setState({loading: true});
        Enquiry.details(this.props.match.params.id)
            .then(response => {
                let enquiry = response.data.data;
                console.log(enquiry);
                let booking_status = null;
                let promoCodeCharges = 0;
                let show_prices = 0;
                let show_promocode = 0;
                if (enquiry.booking) {
                    booking_status = enquiry.booking.booking_status;
                    enquiry.booking = formatRouteEnquiry(enquiry.booking);
                    promoCodeCharges = enquiry.booking.booking_status === USER_ACCEPTED ? enquiry.booking.discount : 0;
                    show_prices = enquiry.booking.pol_agent_company.settings && enquiry.booking.pol_agent_company.settings.show_prices ? parseInt(enquiry.booking.pol_agent_company.settings.show_prices) : 0;
                    show_promocode = enquiry.user.company.settings && enquiry.user.company.settings.show_promocode ? parseInt(enquiry.user.company.settings.show_promocode) : 0;
                }

                let basic_freight = (enquiry.booking && enquiry.booking.basic_freight) ? enquiry.booking.basic_freight : 0;
                let total_freight = (enquiry.booking && enquiry.booking.total_amount && enquiry.booking.total_amount.total_amount_usd_sales) ? parseFloat(enquiry.booking.total_amount.total_amount_usd_sales) - parseFloat(enquiry.booking.discount) : 0;

                let selectedShipper = null;
                if (enquiry && enquiry.booking && enquiry.booking.shipper) {
                    selectedShipper = {
                        label: enquiry.booking.shipper.company_name,
                        value: enquiry.booking.shipper.id,
                        key: enquiry.booking.shipper.id
                    }
                }
                console.log('test 123');
                this.setState({
                    selectedShipper,
                    booking_status,
                    basic_freight,
                    total_freight,
                    promoCodeCharges,
                    originalPromoCodeCharges: 0,
                    title: "",
                    promoCode: "",
                    enquiryDetails: enquiry,
                    loading: false,
                    platform_fees: (enquiry.booking)?enquiry.booking.platform_fees.value:0,
                    show_prices,
                    show_promocode
                });

            }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });

    };

    userAccept = () => {
        this.setState({booking_status: USER_ACCEPTED});
    };

    userReject = () => {
        this.setState({booking_status: USER_REJECTED});
    };

    onSubmit = (event, errors, values) => {
        let shipperError = null;
        let user = Auth.user;
        if (!this.state.selectedShipper && user.company.company_type === 2) {
            shipperError = "Please select Exporter";
            errors.push(shipperError);
        }
        this.setState({shipperError});
        if (errors.length === 0) {
            let data = {
                enquiry_id: this.state.enquiryDetails.id,
                user_remark: (typeof values.user_remark !== 'undefined') ? values.user_remark : '',
                booking_status: parseInt(this.state.booking_status),
                promoCode: this.state.promoCode,
                shipper_id: this.state.selectedShipper ? this.state.selectedShipper.value : ''
            };

            Enquiry.user_approval(this.state.enquiryDetails.booking.id, data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.props.updateEnquiry(response.data.data);
                        console.log(response.data.booking_status, BOOKING_REQUESTED);
                        NotificationManager.success('Success message', response.data.message);

                        this.props.history.push('/enquiry/' + this.state.enquiryDetails.id + '/view');
                        this.getEnquiryDetails();
                    } else {
                        NotificationManager.error('Error message', response.data.message);
                    }
                }).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    handleChange = (name, error, selected) => {
        let errorName = null;
        this.setState({[name]: selected, [error]: errorName});
    };

    render() {
        let local_charges = [], charge = "", count = 0, misc_charge = 0 ;

        let cargoDetails = []
        if (this.state.enquiryDetails.cargo_details) {
            for (let cargo of this.state.enquiryDetails.cargo_details) {
                cargoDetails.push(<tr>
                    <td>{cargo.particular}</td>
                    <td>{equipmentTypes[cargo.equipment_type]}</td>
                    <td>{cargo.length}</td>
                    <td>{cargo.width}</td>
                    <td>{cargo.height}</td>
                    <td>{cargo.weight}</td>
                </tr>);
            }
        }

        let freightCharges = [];

        let freight_charges = {
            'frt_': 'Freight Charges',
            'baf_': 'BAF Charges',
            'caf_': 'CAF Charges',
            'pss_': 'PSS Charges',
            'ams_': 'AMS Charges',
            'gri_': 'GRI Charges',
            'imp_': 'Inland Haulage Import/charges',
            'isps_': 'ISPS Charges',
            'eff_': 'EFF Charges'
        };
        let document_freight_charges = {'ens': 'ENS Charges'};

        if (this.state.enquiryDetails && this.state.enquiryDetails.booking) {

            for (let freight_charge in freight_charges) {

                if (this.state.enquiryDetails.booking.container_20ft > 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20_sales']) > 0) {
                    freightCharges.push(<tr>
                        <td><b>{freight_charges[freight_charge]}</b></td>
                        <td>Per container</td>
                        <td>{this.state.enquiryDetails.container_type_id === REFRIGERATED ? "20 RF" : "20 FT"}</td>
                        <td>{this.state.enquiryDetails.booking.container_20ft}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20_sales'])  }</td>
                            :
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20_sales']) ) * parseInt(this.state.enquiryDetails.booking.container_20ft)}</td>
                            :
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20_sales'])) * parseInt(this.state.enquiryDetails.booking.container_20ft)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }

                if (this.state.enquiryDetails.booking.container_40ft > 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40_sales']) > 0) {
                    freightCharges.push(<tr>
                        <td>
                            <b>{(this.state.enquiryDetails.booking.container_20ft > 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20']) > 0) ? '' : freight_charges[freight_charge]}</b>
                        </td>
                        <td>Per container</td>
                        <td>40FT</td>
                        <td>{this.state.enquiryDetails.booking.container_40ft}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40_sales']) }</td>
                            :
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40_sales']) ) * parseInt(this.state.enquiryDetails.booking.container_40ft)}</td>
                            :
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40_sales'])) * parseInt(this.state.enquiryDetails.booking.container_40ft)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }
                if (this.state.enquiryDetails.booking.container_40hc > 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40hc_sales']) > 0) {
                    freightCharges.push(<tr>
                        <td>
                            <b>{(this.state.enquiryDetails.booking.container_20ft <= 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '20']) <= 0) && (this.state.enquiryDetails.booking.container_40ft <= 0 && parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40']) <= 0) ? freight_charges[freight_charge] : ""}</b>
                        </td>
                        <td>Per container</td>
                        <td>{this.state.enquiryDetails.container_type_id === REFRIGERATED ? "40 RF HC" : "40 HC"}</td>
                        <td>{this.state.enquiryDetails.booking.container_40hc}</td>
                        {freight_charge === "frt_" ?
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40hc_sales']) }</td>
                            :
                            <td>$ {parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40hc_sales'])}</td>
                        }
                        {freight_charge === "frt_" ?
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40hc_sales']) ) * parseInt(this.state.enquiryDetails.booking.container_40hc)}</td>
                            :
                            <td> $ {(parseFloat(this.state.enquiryDetails.booking.freight_charges[freight_charge + '40hc_sales'])) * parseInt(this.state.enquiryDetails.booking.container_40hc)}</td>
                        }
                        <td className="change-td"/>
                    </tr>)
                }
            }
            for (let document_freight_charge in document_freight_charges) {
                if(this.state.enquiryDetails.booking.freight_charges[document_freight_charge]){
                    freightCharges.push(
                        <tr>
                            <td>
                                <b>{document_freight_charges[document_freight_charge]}</b>
                            </td>
                            <td>Per Document</td>
                            <td/>
                            <td/>
                            <td/>
                            <td>$ {this.state.enquiryDetails.booking.freight_charges[document_freight_charge]}</td>
                            <td/>
                        </tr>
                    );
                }
            }
        }

        const hazFiles = [];
        if (this.state.enquiryDetails) {
            for (let haz in this.state.enquiryDetails.haz_documents) {
                var hazFileName = this.state.enquiryDetails.haz_documents[haz].split("/");
                hazFiles.push(<li key={haz}>
                    <a href={AWS_S3_URL + this.state.enquiryDetails.haz_documents[haz]} target="_blank"
                       rel="noopener noreferrer">
                        <img alt={""}/>{hazFileName[hazFileName.length - 1]}
                    </a>
                </li>);
            }
        }


        let user = Auth.user;
        if (this.state.enquiryDetails.booking) {
            if (this.state.enquiryDetails.booking.local_charges) {
                for (let local of this.state.enquiryDetails.booking.local_charges) {
                    count++;
                    if (local.group_as_misc === 1 && local.currency === "INR") {
                        if (local.applicability === "C") {
                            misc_charge += parseFloat(local.charge_20) * parseFloat(this.state.enquiryDetails.booking.container_20ft);
                            misc_charge += parseFloat(local.charge_40) * parseFloat(this.state.enquiryDetails.booking.container_40ft);
                            misc_charge += parseFloat(local.charge_40hc) * parseFloat(this.state.enquiryDetails.booking.container_40hc);
                        } else {
                            misc_charge += parseFloat(local.charge_per_booking);
                        }
                    } else {
                        if (local.applicability === "C") {
                            charge = <Fragment key={count}>
                                {this.state.enquiryDetails.booking.container_20ft > 0 && local.show_on_quotation == true ?
                                
                                    <tr>
                                        <td>{local.title ? local.title.name : ""}</td>
                                        <td>Per Container</td>
                                        <td> { this.state.enquiryDetails.booking.container_type_id === REFRIGERATED ? (this.state.enquiryDetails.booking.container_20ft > 0 ?  '20 RF' : '')
                                        : (this.state.enquiryDetails.booking.container_20ft > 0 ?  '20FT' : '') }</td>
                                        <td>{this.state.enquiryDetails.booking.container_20ft}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_20) > 0 ? local.charge_20 : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_20) > 0 ? parseInt(this.state.enquiryDetails.booking.container_20ft) * parseInt(local.charge_20) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_20) > 0 ? parseInt(this.state.enquiryDetails.booking.container_20ft) * parseInt(local.charge_20) : 0) : ""}</td>
                                    </tr>
                                    : ""}
                                {this.state.enquiryDetails.booking.container_40ft > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{(!this.state.enquiryDetails.booking.container_20ft > 0) ? (local.title ? local.title.name : "") : ""}</td>
                                        <td>Per Container</td>
                                        <td>{ this.state.enquiryDetails.booking.container_type_id === REFRIGERATED ? (this.state.enquiryDetails.booking.container_40ft > 0 ?  '40 RF HC' : '')
                                        : (this.state.enquiryDetails.booking.container_40ft > 0 ?  '40FT' : '') }</td>
                                        <td>{this.state.enquiryDetails.booking.container_40ft}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_40) > 0 ? local.charge_40 : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_40) > 0 ? parseInt(this.state.enquiryDetails.booking.container_40ft) * parseInt(local.charge_40) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_40) > 0 ? parseInt(this.state.enquiryDetails.booking.container_40ft) * parseInt(local.charge_40) : 0) : ""}</td>

                                    </tr> : ""}
                                {this.state.enquiryDetails.booking.container_40hc > 0 && local.show_on_quotation == true ?
                                    <tr>
                                        <td>{(this.state.enquiryDetails.booking.container_20ft <= 0) && (this.state.enquiryDetails.booking.container_40ft <= 0) ? (local.title ? local.title.name : "") : ""}</td>
                                        <td>Per Container</td>
                                        <td>{ this.state.enquiryDetails.booking.container_type_id === REFRIGERATED ? (this.state.enquiryDetails.booking.container_40hc > 0 ?  '40 RF HC' : '')
                                        : (this.state.enquiryDetails.booking.container_40hc > 0 ?  '40 HC' : '') }</td>
                                        <td>{this.state.enquiryDetails.booking.container_40hc}</td>
                                        <td>{local.currency === "INR" ? "(₹) " : "$ "}{parseInt(local.charge_40hc) > 0 ? local.charge_40hc : 0}</td>
                                        <td>{local.currency === "USD" ? "$ " + (parseInt(local.charge_40hc) > 0 ? parseInt(this.state.enquiryDetails.booking.container_40hc) * parseInt(local.charge_40hc) : 0) : ""}</td>
                                        <td>{local.currency === "INR" ? "(₹) " + (parseInt(local.charge_40hc) > 0 ? parseInt(this.state.enquiryDetails.booking.container_40hc) * parseInt(local.charge_40hc) : 0) : ""}</td>
                                    </tr> : ""}
                            </Fragment>;
                            local_charges.push(charge);

                        } else {
                             if(local.show_on_quotation == true){
                                charge = <tr key={count}>
                                    <td><b>{local.title ? local.title.name : ""}</b></td>
                                    <td>Per Document</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td>{local.currency === "USD" ? "$ " + local.charge_per_booking_sales : ""}</td>
                                    <td>{local.currency === "INR" ? "(₹) " + local.charge_per_booking_sales : ""}</td>
                                </tr>;
                                local_charges.push(charge);
                             }
                        }
                    }
                }
            }
            local_charges.push(<tr>
                    <td><b>Miscellaneous </b></td>
                    <td>Per Document</td>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                    <td>{"(₹) " + misc_charge}</td>
                </tr>
            );
        }
        return ( this.state.loading ?
                <Fragment>
                    <div className="booking-history-heading no-booking">
                        Loading ...
                    </div>
                </Fragment> :
                <Fragment>
                    <div className="booking-history-box">
                        <div className="booking-history-heading">
                            <h6>Enquiry Ref No <b>{this.state.enquiryDetails.enq_id}</b></h6>
                        </div>
                    </div>
                    <div className="update-booking">
                        <Row>
                            <Col lg="4" sm="12">
                                <div className="booing-status">
                                    <ul>
                                        <li>
                                            <p>Enquiry Status</p>
                                            <b>{this.state.enquiryDetails.booking ? bookingStatus[this.state.enquiryDetails.booking.booking_status] : "Vendor Approval Pending"}</b>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="8" sm="12">
                                <div className="bl-btn">
                                    <ul>
                                        <li>
                                            {this.state.enquiryDetails && this.state.enquiryDetails.booking !== null && this.state.show_prices === 1 ?
                                                <button onClick={this.toggleopenPriceBreakdownModal}>View
                                                    Price Breakdown
                                                </button> : null
                                            }
                                        </li>
                                        &nbsp;
                                        <li>
                                            <div className="enquiry-details-button">
                                                <a color="primary" href={"/check-list/" + this.state.enquiryDetails.id}>Create
                                                    Checklist</a>
                                            </div>
                                        </li>
                                        &nbsp;
                                        <li>
                                            <div className="enquiry-details-button">
                                                <a color="primary"
                                                   href={"/add-enquiry/" + this.state.enquiryDetails.id}>Copy
                                                    Enquiry</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Port of loading</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.pol) ? this.state.enquiryDetails.pol.port_name : ""}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Port of discharge</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.pod) ? this.state.enquiryDetails.pod.port_name : ""}</p>
                            </div>
                        </div>

                        {this.state.enquiryDetails && this.state.enquiryDetails.enquiry_type !== OVERDIMENSIONAL ?
                            <div className="col-lg-4 col-sm-4 col-12">
                                <div className="enquiry-view-text">
                                    <b>Commodity</b>
                                    <p>{(this.state.enquiryDetails && this.state.enquiryDetails.commodity) ? this.state.enquiryDetails.commodity.title : ""}</p>
                                </div>
                            </div>
                            : null
                        }

                        {this.state.enquiryDetails && this.state.enquiryDetails.enquiry_type === RATES_UNAVAILABLE ?
                            <Fragment>
                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>ETD at POL</b>
                                        <p>{(this.state.enquiryDetails && this.state.enquiryDetails.etd_pol) ? moment(this.state.enquiryDetails.etd_pol, 'YYYY-MM-DD').format('DD-MM-YYYY') : ""}</p>
                                    </div>
                                </div>
                            </Fragment> : null
                        }

                        {this.state.enquiryDetails && this.state.enquiryDetails.enquiry_type !== OVERDIMENSIONAL ?
                            <div className="col-lg-4 col-sm-4 col-12">
                                <div className="enquiry-view-text">
                                    <b>No of containers</b>
                                    {this.state.enquiryDetails.container_type_id === REFRIGERATED ?
                                        <p> {(this.state.enquiryDetails.container_20ft > 0 ? this.state.enquiryDetails.container_20ft + ' X 20 RF' : '') + (((this.state.enquiryDetails.container_40hc > 0 && this.state.enquiryDetails.container_20ft > 0) || (this.state.enquiryDetails.container_40ft > 0 && this.state.enquiryDetails.container_20ft > 0)) ? ', ' : '') + (this.state.enquiryDetails.container_40ft > 0 ? this.state.enquiryDetails.container_40ft + ' X 40FT' : '') + (this.state.enquiryDetails.container_40ft > 0 && this.state.enquiryDetails.container_40hc > 0 ? ', ' : '') + (this.state.enquiryDetails.container_40hc > 0 ? this.state.enquiryDetails.container_40hc + ' X 40 RF HC' : '')}</p>
                                        :
                                        <p> {(this.state.enquiryDetails.container_20ft > 0 ? this.state.enquiryDetails.container_20ft + ' X 20FT' : '') + (((this.state.enquiryDetails.container_40hc > 0 && this.state.enquiryDetails.container_20ft > 0) || (this.state.enquiryDetails.container_40ft > 0 && this.state.enquiryDetails.container_20ft > 0)) ? ', ' : '') + (this.state.enquiryDetails.container_40ft > 0 ? this.state.enquiryDetails.container_40ft + ' X 40FT' : '') + (this.state.enquiryDetails.container_40ft > 0 && this.state.enquiryDetails.container_40hc > 0 ? ', ' : '') + (this.state.enquiryDetails.container_40hc > 0 ? this.state.enquiryDetails.container_40hc + ' X 40HC' : '')}</p>
                                    }
                                </div>
                            </div> : null
                        }

                        {(this.state.enquiryDetails && (this.state.enquiryDetails.enquiry_type === RATES_UNAVAILABLE || this.state.enquiryDetails.enquiry_type === HAZARDOUS_RATES)) ?
                            <div className="col-lg-4 col-sm-4 col-12">
                                <div className="enquiry-view-text">
                                    <b>Cargo Weight (Per Container)</b>
                                    <p>{(this.state.enquiryDetails && this.state.enquiryDetails.cargo_weight && this.state.enquiryDetails.cargo_weight['20']) ?
                                        (this.state.enquiryDetails.container_type_id === REFRIGERATED ? "20 RF :" : "20 FT : ") + this.state.enquiryDetails.cargo_weight['20'] + ' KGS' : ""}</p>
                                    <p>{(this.state.enquiryDetails && this.state.enquiryDetails.cargo_weight && this.state.enquiryDetails.cargo_weight['40']) ?
                                        (this.state.enquiryDetails.container_type_id === REFRIGERATED ? "40 ft :" : "40 FT : ") + this.state.enquiryDetails.cargo_weight['40'] + ' KGS' : ""}</p>
                                    <p>{(this.state.enquiryDetails && this.state.enquiryDetails.cargo_weight && this.state.enquiryDetails.cargo_weight['40hc']) ?
                                        (this.state.enquiryDetails.container_type_id === REFRIGERATED ? "40 RF HC :" : "40 HC : ") + this.state.enquiryDetails.cargo_weight['40hc'] + ' KGS' : ""}</p>
                                </div>
                            </div> : null
                        }

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Container Type</b>
                                <p>{(this.state.enquiryDetails) ? containerTypes[this.state.enquiryDetails.container_type_id] : ""}</p>
                            </div>
                        </div>
                        {this.state.enquiryDetails && this.state.enquiryDetails.enquiry_type === HAZARDOUS_RATES ?
                            <Fragment>
                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>DG Class</b>
                                        <p>{(this.state.enquiryDetails && this.state.enquiryDetails.imo_class) ? "(" + this.state.enquiryDetails.imo_class.value + ")" + this.state.enquiryDetails.imo_class.description : ""}</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>Packing Group</b>
                                        <p>{(this.state.enquiryDetails) ? 'Packing Group ' + this.state.enquiryDetails.packing_group : ""}</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>UN Number</b>
                                        <p>{(this.state.enquiryDetails) ? this.state.enquiryDetails.un_number : ""}</p>
                                    </div>
                                </div>
                            </Fragment>
                            : null}

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Carrier / Shipping Line Preference</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.carrier_preference : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Preferred Empty Pick up Location</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.pick_up_location : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Type of Stuffing</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.stuffing : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Stuffing Location</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.stuffing_location : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Pick-up Date</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? moment(this.state.enquiryDetails.details.position_start_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss') : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Gate-in Date</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? moment(this.state.enquiryDetails.details.position_end_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss') : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Vessel</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.vessel : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Voyage</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.details) ? this.state.enquiryDetails.details.voyage : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Freight Terms</b>
                                <p>{(this.state.enquiryDetails) ? (this.state.enquiryDetails.freight_terms === 1 ? 'Prepaid' : 'Collect') : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Type of Service</b>
                                <p>{(this.state.enquiryDetails && this.state.enquiryDetails.type_of_service) ? this.state.enquiryDetails.type_of_service : ""}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Shipper Name</b>
                                <p>{this.state.enquiryDetails.shipper ? this.state.enquiryDetails.shipper.company_name : "NA"}</p>
                            </div>
                        </div>
                        {/*<div className="col-lg-4 col-sm-4 col-12">*/}
                        {/*    <div className="enquiry-view-text">*/}
                        {/*        <b>Booking Party</b>*/}
                        {/*        <p>{this.state.enquiryDetails.user.company.name}</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Booking Party</b>
                                <p>{this.state.enquiryDetails.booking_party ? this.state.enquiryDetails.booking_party.company_name :(this.state.enquiryDetails.forwarder ? this.state.enquiryDetails.forwarder.company_name  : (this.state.enquiryDetails.cha ? this.state.enquiryDetails.cha.company_name : this.state.enquiryDetails.user.company.name))}</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 col-12">
                            <div className="enquiry-view-text">
                                <b>Invoice Party</b>
                                <p>{this.state.enquiryDetails.invoice_party ? this.state.enquiryDetails.invoice_party.company_name :(this.state.enquiryDetails.forwarder ? this.state.enquiryDetails.forwarder.company_name  : (this.state.enquiryDetails.cha ? this.state.enquiryDetails.cha.company_name : this.state.enquiryDetails.user.company.name))}</p>
                            </div>
                        </div>

                        {(this.state.enquiryDetails.details.freight_chrages_20 || this.state.enquiryDetails.details.freight_chrages_40 || this.state.enquiryDetails.details.freight_chrages_40hc) ?
                            <div className="col-lg-3 col-sm-3 col-12">
                                <div className="enquiry-view-text">
                                    <b>Freight Required or Requested </b>
                                    <p> {(this.state.enquiryDetails.details.freight_chrages_20 && true && this.state.enquiryDetails.details.freight_chrages_20 !== '' ? 'USD ' +this.state.enquiryDetails.details.freight_chrages_20 : '')}</p>
                                    <p> {(this.state.enquiryDetails.details.freight_chrages_40 && true && this.state.enquiryDetails.details.freight_chrages_40 !== '' ?'USD '+ this.state.enquiryDetails.details.freight_chrages_40 : '')}</p>
                                    <p> {(this.state.enquiryDetails.details.freight_chrages_40hc && true && this.state.enquiryDetails.details.freight_chrages_40hc !== '' ? 'USD '+ this.state.enquiryDetails.details.freight_chrages_40hc : '')}</p>
                                </div>
                            </div> : null}


                        {this.state.enquiryDetails && this.state.enquiryDetails.consignee ?
                            <div className="col-lg-4 col-sm-4 col-12">
                                <div className="enquiry-view-text">
                                    <b>Consignee Name</b>
                                    <p>{this.state.enquiryDetails.consignee.company_name}</p>
                                </div>
                            </div> : null}

                        {this.state.enquiryDetails && this.state.enquiryDetails.consignee ?
                            <div className="col-lg-4 col-sm-4 col-12">
                                <div className="enquiry-view-text">
                                    <b>Consignee Address</b>
                                    <p>{this.state.enquiryDetails.consignee.address}</p>
                                </div>
                            </div> : null}

                        {this.state.enquiryDetails.packing_group ?
                            <Fragment>
                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>DG Class</b>
                                        <p>{this.state.enquiryDetails && this.state.enquiryDetails.imo_class ? "(" + this.state.enquiryDetails.imo_class.value + ")" + this.state.enquiryDetails.imo_class.description : ''}</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>Packing Group</b>
                                        <p>{'Packing Group ' + this.state.enquiryDetails.packing_group}</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-4 col-12">
                                    <div className="enquiry-view-text">
                                        <b>UN Number</b>
                                        <p>{this.state.enquiryDetails.un_number}</p>
                                    </div>
                                </div>

                            </Fragment>
                            : null}
                    </div>

                    <div className="row">

                        <div className="col-lg-12 col-sm-12 col-12">
                            <div className="enquiry-view-text">
                                <b>Goods Description</b>
                                <p>{(this.state.enquiryDetails) ? this.state.enquiryDetails.goods_description : "NA"}</p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                            <div className="enquiry-view-text">
                                <b>Remarks</b>
                                <p>{(this.state.enquiryDetails) ? this.state.enquiryDetails.remarks : "NA"}</p>
                            </div>
                        </div>

                        {this.state.enquiryDetails.haz_documents && this.state.enquiryDetails.haz_documents.length ?
                            <div className="col-lg-12 col-sm-12 col-12">
                                <div className="enquiry-view-text">
                                    <b>Hazardous Documents</b>
                                    <ul>
                                        {hazFiles}
                                    </ul>

                                </div>
                            </div> : null}
                    </div>

                    {
                        cargoDetails.length ?
                            <div>
                                <Table>
                                    <thead>
                                    <th> Particulars</th>
                                    <th> Type Of Equipment</th>
                                    <th> Length(cm)</th>
                                    <th> Width(cm)</th>
                                    <th> Height(cm)</th>
                                    <th> Weight(kg)</th>
                                    </thead>
                                    <tbody>
                                    {cargoDetails}
                                    </tbody>
                                </Table>
                            </div> : null
                    }

                    {
                        this.state.enquiryDetails && this.state.enquiryDetails.booking !== null ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="enquiry-view-head">
                                            Shipment details
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Shipment Ref No</b>
                                            <p>{this.state.enquiryDetails.booking.book_id}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Booking Status</b>
                                            <p>{bookingStatus[this.state.enquiryDetails.booking.booking_status]}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Booking Date</b>
                                            <p>{moment(this.state.enquiryDetails.booking.created_at).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>ETD at POL</b>
                                            <p>{this.state.enquiryDetails.booking.etd_pol}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>ETA at POD</b>
                                            <p> {this.state.enquiryDetails.booking.eta_pod}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Commodity</b>
                                            <p> {this.state.enquiryDetails.commodity.title}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Cut off</b>
                                            <p>{this.state.enquiryDetails.booking.cut_off_date ? this.state.enquiryDetails.booking.cut_off_date : "NA"}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Shipping Line</b>
                                            <p>{this.state.enquiryDetails.booking.ship_line}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="enquiry-view-text">
                                            <b>Approximate Transit Time</b>
                                            <p>{this.state.enquiryDetails.booking.transit_time ? this.state.enquiryDetails.booking.transit_time + " days" : "NA"} </p>
                                        </div>
                                    </div>
                                    {this.state.enquiryDetails.booking.is_haz ?
                                        <Fragment>
                                            <div className="col-lg-4 col-sm-4 col-12">
                                                <div className="enquiry-view-text">
                                                    <b>UN Number</b>
                                                    <p>{this.state.enquiryDetails.booking.un_number}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-4 col-12">
                                                <div className="enquiry-view-text">
                                                    <b>Packing Group</b>
                                                    <p>Group {this.state.enquiryDetails.booking.packing_group === 1 ? "I" : (this.state.enquiryDetails.booking.packing_group === 2 ? "II" : "III")}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-sm-4 col-12">
                                                <div className="enquiry-view-text">
                                                    <b>DG Class</b>
                                                    <p>{(this.state.enquiryDetails.booking && this.state.enquiryDetails.booking.imo_class) ? this.state.enquiryDetails.booking.imo_class.description : ""}</p>
                                                </div>
                                            </div>
                                        </Fragment>

                                        : null
                                    }
                                </div>
                                {/*<hr/>*/}
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <div className="enquiry-view-head">
                                            Quotation Details
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-4 col-12">

                                        <div className="enquiry-view-text">
                                            <b>Cargo Weight (Per Container)</b>
                                            <p>{(this.state.enquiryDetails.booking.cargo_weight_20ft !== null && this.state.enquiryDetails.booking.cargo_weight_20ft > 0) ? (this.state.enquiryDetails.booking.container_type_id === REFRIGERATED ? '20 RF : ' : '20 FT : ') + this.state.enquiryDetails.booking.cargo_weight_20ft + ' Kgs' : null}</p>
                                            <p>{(this.state.enquiryDetails.booking.cargo_weight_40ft !== null && this.state.enquiryDetails.booking.cargo_weight_40ft > 0) ? '40 FT : ' + this.state.enquiryDetails.booking.cargo_weight_40ft + ' Kgs' : null}</p>
                                            <p>{(this.state.enquiryDetails.booking.cargo_weight_40hc !== null && this.state.enquiryDetails.booking.cargo_weight_40hc > 0) ? (this.state.enquiryDetails.booking.container_type_id === REFRIGERATED ? '40 RF HC : ' : '40 HC : ') + this.state.enquiryDetails.booking.cargo_weight_40hc + ' Kgs' : null}</p>
                                        </div>
                                    </div>
                                    {this.state.show_prices === 1 ?
                                        <Fragment>
                                            <div className="col-lg-3 col-sm-3 col-12">
                                                <div className="enquiry-view-text">
                                                    <b>Hazardous / Dangerous good</b>
                                                    <p>{this.state.enquiryDetails.booking.is_haz === 1 ? 'USD ' + this.state.enquiryDetails.booking.haz_charges : 'NA'}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-4 col-12">
                                                <div className="enquiry-view-text">
                                                    <b>Total Freight <button title="View Price Breakdown"
                                                                             className="freight-chrg-btn"
                                                                             onClick={this.toggleopenPriceBreakdownModal}>
                                                        <i className="fa fa-exclamation"/>
                                                    </button></b>
                                                    {/* <p>USD {this.state.enquiryDetails.booking.total_amount && this.state.enquiryDetails.booking.total_amount['total_freight'] ? this.state.enquiryDetails.booking.total_amount['total_freight'] : ""}</p> */}
                                                    <p>
                                                    USD{" "}
                                                    { ((this.state.enquiryDetails.booking.container_20ft > 0) ? (this.state.enquiryDetails.booking.freight_charges['frt_20_sales'] == undefined ? this.state.enquiryDetails.booking.freight_charges['frt_20'] * this.state.enquiryDetails.booking.container_20ft : this.state.enquiryDetails.booking.freight_charges['frt_20_sales'] * this.state.enquiryDetails.booking.container_20ft ) : 0 ) +
                                                    ((this.state.enquiryDetails.booking.container_40ft > 0) ? (this.state.enquiryDetails.booking.freight_charges['frt_40_sales'] == undefined ? this.state.enquiryDetails.booking.freight_charges['frt_40'] * this.state.enquiryDetails.booking.container_40ft : this.state.enquiryDetails.booking.freight_charges['frt_40_sales'] * this.state.enquiryDetails.booking.container_40ft ) : 0 ) + 
                                                    ((this.state.enquiryDetails.booking.container_40hc > 0) ? (this.state.enquiryDetails.booking.freight_charges['frt_40hc_sales'] == undefined ? this.state.enquiryDetails.booking.freight_charges['frt_40hc'] * this.state.enquiryDetails.booking.container_40hc : this.state.enquiryDetails.booking.freight_charges['frt_40hc_sales'] * this.state.enquiryDetails.booking.container_40hc ) : 0 )
                                                    }
                                                    
                                                    {/* {this.state.enquiryDetails.booking.total_freight} */}
                                                </p>
                                                </div>
                                            </div>
                                        </Fragment> : null}
                                    {this.state.enquiryDetails.booking.booking_status === USER_REJECTED ?
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="enquiry-view-text">
                                                <b>Remarks for Rejection</b>
                                                <p>{this.state.enquiryDetails.booking.user_remark}</p>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            </Fragment>
                            : null
                    }

                    {
                        this.state.enquiryDetails.booking ?
                            <Modal className="price-breackdown-popup" isOpen={this.state.openPriceBreakdownModal}
                                   toggle={this.toggleopenPriceBreakdownModal}>
                                <ModalHeader toggle={this.toggleopenPriceBreakdownModal}>
                                    Price Breakdown ( India Specific )
                                </ModalHeader>
                                <ModalBody className="price-breakdown-table">
                                    <Container>
                                        <div className="pol-full-line">
                                            <Row>
                                                <Col lg="12" sm="12">
                                                    <p className="table-full-price-note"><b>NOTE: </b> GST applicable
                                                        are applicable as per law.</p>
                                                </Col>
                                                <Col lg="4" sm="4">
                                                    <div className="pol-view-price">
                                                        <b>Pol</b>
                                                        <p>{this.state.enquiryDetails.pol.un_code} - {this.state.enquiryDetails.pol.port_name}</p>
                                                    </div>
                                                </Col>
                                                <Col lg="4" sm="4">
                                                    <div className="pol-view-price">
                                                        <b>Pod</b>
                                                        <p>{this.state.enquiryDetails.pod.un_code} - {this.state.enquiryDetails.pod.port_name}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12" sm="12">
                                                    <div className="table-full-price">
                                                        <Table className="">
                                                            <thead>
                                                            <tr>
                                                                <th>Heads</th>
                                                                <th>Basis</th>
                                                                <th>Type</th>
                                                                <th>Qty</th>
                                                                <th>Per container Price</th>
                                                                <th>usd</th>
                                                                <th>inr</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr className="border-none">
                                                                <td colSpan="4" className="local-area color-one">
                                                                    <a href={"#"}>international</a>
                                                                </td>
                                                            </tr>

                                                            {freightCharges}
                                                            {this.state.enquiryDetails.booking.is_haz ?
                                                                <Fragment>
                                                                    {this.state.enquiryDetails.booking.container_20ft > 0  ?
                                                                        <tr>
                                                                            <td><b>Hazardous</b></td>
                                                                            <td>Per container</td>
                                                                            <td>20FT</td>
                                                                            <td>{this.state.enquiryDetails.booking.container_20ft}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_20']}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_20'] * this.state.enquiryDetails.booking.container_20ft}</td>
                                                                            <td className="change-td"/>
                                                                        </tr> : ""}
                                                                    {this.state.enquiryDetails.booking.container_40ft > 0 ?
                                                                        <tr>
                                                                            <td>{(!this.state.enquiryDetails.booking.container_20ft > 0) ? "Hazardous" : ""}</td>
                                                                            <td>Per container</td>
                                                                            <td>40FT</td>
                                                                            <td>{this.state.enquiryDetails.booking.container_40ft}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_40']}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_40'] * this.state.enquiryDetails.booking.container_40ft}</td>
                                                                            <td className="change-td"/>
                                                                        </tr> : ""}
                                                                    {this.state.enquiryDetails.booking.container_40hc > 0 ?
                                                                        <tr>
                                                                            <td>{(this.state.enquiryDetails.booking.container_20ft <= 0) && (this.state.enquiryDetails.booking.container_40ft <= 0) ? "Hazardous" : ""}</td>
                                                                            <td>Per container</td>
                                                                            <td>40HC</td>
                                                                            <td>{this.state.enquiryDetails.booking.container_40hc}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_40hc']}</td>
                                                                            <td>$ {this.state.enquiryDetails.booking.freight_charges['haz_40hc'] * this.state.enquiryDetails.booking.container_40hc}</td>
                                                                            <td className="change-td"/>
                                                                        </tr> : ""}
                                                                </Fragment> : ""}

                                                            {parseInt(this.state.promoCodeCharges) > 0 ?
                                                                <tr>
                                                                    <td><b>Discount</b></td>
                                                                    <td/>
                                                                    <td/>
                                                                    <td/>
                                                                    <td/>
                                                                    <td>$ {this.state.promoCodeCharges}</td>
                                                                    <td/>
                                                                </tr> : null}

                                                            <tr className="border-none">
                                                                <td colSpan="6" className="local-area">
                                                                    <a href={"#"}>Local</a>
                                                                </td>
                                                            </tr>
                                                            {local_charges}
                                                            { this.state.platform_fees != 0? <tr>
                                                                <td><b>Platform fee</b></td>
                                                                <td>Per Document</td>
                                                                <td/>
                                                                <td/>
                                                                <td/>
                                                                <td>$ {this.state.platform_fees}</td>
                                                                <td/>
                                                            </tr> :null }

                                                            <tr>
                                                                <td><b className="price-breakdown-table">Total
                                                                    Charges</b>
                                                                </td>
                                                                <td className="change-td total-charge-last-one"
                                                                    colSpan="5">USD
                                                                    ($) {this.state.total_freight}</td>
                                                                <td className="change-td total-charge-last">INR
                                                                    (₹) {this.state.enquiryDetails.booking.total_amount['total_amount_inr_sales']}
                                                                </td>
                                                            </tr>
                                                            </tbody>

                                                        </Table>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Container>

                                </ModalBody>
                            </Modal>
                            : null
                    }
                    <Fragment>
                        <AvForm onSubmit={this.onSubmit}>
                            {this.state.enquiryDetails.container_type_id === REFRIGERATED ?
                                <Fragment>
                                    {/*<hr/>*/}
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="enquiry-view-head">
                                                Cargo is Reefer Commodity
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="pol-view-price">
                                                <b>Temperature ( ºC )</b>
                                                <p>{this.state.enquiryDetails && this.state.enquiryDetails.reefer_details ? this.state.enquiryDetails.reefer_details['temperature'] : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="pol-view-price">
                                                <b>Humidity ( % )</b>
                                                <p>{this.state.enquiryDetails && this.state.enquiryDetails.reefer_details ? this.state.enquiryDetails.reefer_details['humidity'] : ""}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="pol-view-price">
                                                <b>Ventilation ( CBM / Per Hour )</b>
                                                <p>{this.state.enquiryDetails && this.state.enquiryDetails.reefer_details ? this.state.enquiryDetails.reefer_details['ventilation'] : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment> :
                                null
                            }

                            {/*<div className="row">
                                {((this.state.enquiryDetails.enquiry_type === RATES_UNAVAILABLE && this.state.enquiryDetails.booking && this.state.enquiryDetails.booking.booking_status === USER_APPROVAL_PENDING && this.state.booking_status !== USER_REJECTED && this.state.show_promocode === 1)
                                    || this.state.enquiryDetails.enquiry_type === HAZARDOUS_RATES && this.state.enquiryDetails.booking && this.state.enquiryDetails.booking.booking_status === USER_APPROVAL_PENDING && this.state.booking_status !== USER_REJECTED && this.state.show_promocode === 1) ?
                                    <div className="col-lg-4 col-sm-4 col-12">
                                        <div className="commodity-box-one enquiry-view-text">
                                            <b>Promo Code</b>
                                            <div className={"promo-code-section"}>
                                                <input type="text" placeholder={"Promocode"}
                                                       value={this.state.title}
                                                       onChange={this.handlePromoCode}
                                                       disabled={this.state.promoCodeCharges}/>
                                                {this.state.promoCodeCharges === 0 ?
                                                    <a className="probtn" onClick={this.checkPromoCode}>
                                                        <i className="fa fa-check"/>
                                                    </a> :
                                                    <a className="probtn" onClick={this.removePromoCode}>
                                                        <i className="fa fa-times"/>
                                                    </a>}
                                            </div>
                                            <span className={"auth-error"}>{this.state.invalidPromoCode}</span>
                                        </div>
                                    </div> : null
                                }
                            </div>*/}

                            {((user && user.company.company_type) === 2 && this.state.enquiryDetails.booking && this.state.enquiryDetails.booking.booking_status === USER_APPROVAL_PENDING) ?
                                <div className="col-lg-3 col-sm-3 col-12">
                                    <label>Shipper <i title="Add Shipper" className="fa fa-plus-circle"
                                                      onClick={() => this.toggleShipperModal()}/></label>
                                    <Select
                                        className="home-select"
                                        name="exporter"
                                        value={this.state.selectedShipper}
                                        placeholder="Select Shipper"
                                        noOptionsMessage={() => "No record found"}
                                        onChange={(selected) => this.handleChange('selectedShipper', 'shipperError', selected)}
                                        options={this.state.shipperList}
                                    />
                                    <CustomFeedback>{this.state.shipperError}</CustomFeedback>
                                </div>
                                : null
                            }

                            {this.state.enquiryDetails.booking && this.state.enquiryDetails.booking.booking_status === USER_APPROVAL_PENDING ?
                                <div className="row">
                                    <div className="bl_sub">
                                        <button className="btn primary-btn" id="approve_bl"
                                                onClick={this.userAccept}>
                                            Accept and Book
                                        </button>
                                    </div>
                                </div> : null}
                        </AvForm>
                    </Fragment>
                    <NotificationContainer/>
                </Fragment>
        )
    }
}

const mapStateToProps = ({auth, enquiries}) => {
    return {auth, enquiries};
};

export default connect(
    mapStateToProps,
    {setActiveEnquiry, showAuthModal, updateEnquiry}
)(EnquiryDetails);