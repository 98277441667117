import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import formIcon from "../../assets/images/form-icon.png";
import {Link} from "react-router-dom";
import {bookingStatus} from "../../constants/bookingStatus";


class HistoryCard extends Component {

    render() {
        const className = this.props.enquiries.activeEnquiry === this.props.enquiryDetails.id ? "all-box active-booking" : "all-box";
        const enqId = (this.props.enquiryDetails.book_id) ? this.props.enquiryDetails.enquiry_id : this.props.enquiryDetails.id;
        return (
            <Fragment>
                <Link to={"/enquiry/" + enqId + "/view"}>
                    <div className={className}>
                        <div className="booking-bl">
                            <h3> Enquiry Ref No <b>{this.props.enquiryDetails.enq_id}</b></h3>
                            <Row>
                                <Col lg="4" sm="4" className="pr-0">
                                    <div className="booking-name">

                                        <span>{(this.props.enquiryDetails.id)?this.props.enquiryDetails.pol.country.name:null}</span>
                                        <b className="port-name">{(this.props.enquiryDetails.id)?this.props.enquiryDetails.pol.port_name:null}</b>
                                    </div>
                                </Col>
                                <Col lg="4" sm="4">
                                    <div className="boat-day">
                                        <img src={formIcon} alt=""/>
                                    </div>
                                </Col>
                                <Col lg="4" sm="4">
                                    <div className="booking-name">
                                        <span>{(this.props.enquiryDetails.id)?this.props.enquiryDetails.pod.country.name:null}</span>
                                        <b className="port-name">{(this.props.enquiryDetails.id)?this.props.enquiryDetails.pod.port_name:null}</b>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col lg="12" sm="12">
                                <div className="booking-highlight">
                                    <p>{  this.props.enquiryDetails.booking ? bookingStatus[this.props.enquiryDetails.booking.booking_status] : "Vendor Approval Pending"}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </Fragment>
        );
    }
}

const mapStateToProps = ({enquiries}) => {
    return {enquiries};
};
export default connect(
    mapStateToProps,
    {}
)(HistoryCard);

// export default HistoryCard;