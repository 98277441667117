import Request from "./api.service";
import {API_URL} from "../constants/apiConstants";

export default class Booking {

    static history() {
        return Request.get(`${Request.base_url}user/booking`);
    }

    static recent() {
        return Request.get(`${Request.base_url}user/recent-bookings`);
    }

    static add(payload) {
        /* let payload = {
            'commodity_type_id': data.deal.commodity_type_id,
            'container_size': data.container_size,
            'total_containers': data.total_containers,
            'container_type': 'Standard',
            'is_dg': data.is_dg ? 1 : 0,
            'insurance': data.insurance ? 1 : 0,
            'dg_class': data.dg_class,
            'route_id': data.deal.route_id,
            'cut_off_date': data.deal.cut_off_date.format('YYYY-MM-DD'),
            'etd_pol': data.deal.etd_pol.format('YYYY-MM-DD'),
            'eta_pod': data.deal.eta_pod.format('YYYY-MM-DD')
        }; */

        return Request.post(`${Request.base_url}user/booking`, payload);
    }

    static details(id) {
        return Request.get(`${Request.base_url}user/booking/${id}`);
    }

    static updateVgm(id, data) {
        data = {...data, '_method': 'PUT'};
        return Request.post(API_URL + 'user/booking/vgm/' + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static cancel_booking(data) {
        return Request.post(`${Request.base_url}user/booking/cancel`, data);
    }

    static sendToShipLine(data) {
        return Request.post(API_URL + 'user/documents/send-to-shipline', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static upload_documents(data) {
        return Request.post(API_URL + 'user/booking/other_documents', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static document_delete(id, doc, key) {
        return Request.get(API_URL + 'user/booking/documents/' + id + '/' + doc + '/' + key);
    }

}