import {
    FETCH_ENQUIRIES,
    FETCH_ENQUIRY_SUCCESS,
    REFRESH_ENQUIRIES,
    SET_ACTIVE_ENQUIRY, UPDATE_ENQUIRY
} from "../../constants/actionTypes";

const INIT_STATE = {
    is_loading: false,
    loaded: false,
    activeEnquiry: 0,
    list: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ENQUIRIES:
            return {...state, is_loading: true};
        case FETCH_ENQUIRY_SUCCESS:
            return {
                ...state,
                loaded: true,
                is_loading: false,
                list: action.payload
            };
        case REFRESH_ENQUIRIES:
            return {...INIT_STATE};
        case SET_ACTIVE_ENQUIRY:
            return {...state, activeEnquiry: parseInt(action.payload)};
        case UPDATE_ENQUIRY:
            let enquiryList = state.list;
            for (let enquiry in enquiryList) {
                if (parseInt(action.payload.id) === parseInt(enquiryList[enquiry].id)) {
                    enquiryList[enquiry] = action.payload;
                }
            }
            return {...state, list:enquiryList}
        default:
            return {...state};
    }
}

