import {ADD_PORTS, FETCH_PORTS} from '../../constants/actionTypes';

export const addPorts = (port_list) => ({
    type: ADD_PORTS,
    payload: port_list
});

export const fetchPorts = () => ({
    type: FETCH_PORTS,
    payload: []
});