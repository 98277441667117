import {FETCH_CONTACTS} from "../../constants/actionTypes";
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {fetchContactsSuccess, fetchContactsFailure} from "./actions";

import Contact from "../../util/contact.service";
import {NotificationManager} from "react-notifications";

const fetchContactsAsync = async () => {
    return await Contact.all()
        .then(response => response)
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
};

function* fetchContactsFromApi() {
    try {
        const address_data = yield call(fetchContactsAsync);
        if (address_data.data.status !== 'failed') {
            yield put(fetchContactsSuccess(address_data.data.data));
        }
    } catch (error) {
        yield put(fetchContactsFailure());
    }
};

export function* watchFetchContacts() {
    yield takeEvery(FETCH_CONTACTS, fetchContactsFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchContacts)
    ]);
}