export const contactType = {
    1: "Shipper",
    2: "Consignee",
    3: "Notify Party",
    4: "Booking Party",
    5: "CHA",
    6: "Forwarder",
    7: "Invoice Party"
};

const getKeyByValue = (value) => {
    for (let key in contactType)
        if (contactType[key] === value)
            return parseInt(key);
    return false;
};

export const SHIPPER = getKeyByValue('Shipper');
export const CONSIGNEE = getKeyByValue('Consignee');
export const NOTIFY_PARTY = getKeyByValue('Notify Party');
export const BOOKING_PARTY = getKeyByValue('Booking Party');
export const CHA = getKeyByValue('CHA');
export const FORWARDER = getKeyByValue('Forwarder');
export const INVOICE_PARTY = getKeyByValue('Invoice Party');