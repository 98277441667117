import React, {Component, Fragment} from "react";
import collect from "collect.js";
import "react-datepicker/dist/react-datepicker.css";
import {containerTypes, REFRIGERATED} from "../../../../constants/containerTypes";

class VgmView extends Component {

    state = {
        booking: null,
        containerId: null,
        packageType: null,
        selectedContainers: null,
        tareWeightError: null,
        cargoWeightError: null,
        WeightError: null,
        weighingTimeError: null,
        weighingTime: new Date(),
        cargo_weight: null,
        tare_weight: null,
        max_weight_limit: null,
        cargo_weight_unit: 1,
        tare_weight_unit: 1,
        max_weight_limit_unit: 1,
        verified_gross_mass: 0
    };

    componentDidMount() {
        let booking = this.props.booking;
        let containerId = this.props.containerId;
        let containers = collect(booking.containers);
        containers = containers.where('id', containerId);
        containers = containers.toArray();
        containers = containers[0];
        let company_type = parseInt(booking.user.company.company_type);
        let company_reg_no = company_type === 1 ? booking.user.company.reg_no : booking.shipper_reg_no;
        this.setState({
            containerId: containerId,
            booking: booking,
            selectedContainers: containers,
            company_reg_no,
            packageType: this.props.packageType,
            cargo_weight: containers.cargo_weight,
            tare_weight: containers.tare_weight,
            max_weight_limit: containers.max_weight_limit,
            verified_gross_mass: containers.verified_gross_mass,
            cargo_weight_unit: 1,
            tare_weight_unit: 1,
            max_weight_limit_unit: 1,
            container_freight_limit: (booking.containers[0].container_size === "20") ? booking['frt_20_wt_limit'] : (booking.containers[0].container_size === "40" ? booking['frt_40_wt_limit'] : booking['frt_40hc_wt_limit']),
        });
    };


    render() {

        return (this.state.booking) ? (
            <div>
                <div className="div-static-data-full vgmview-list">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Booking No</label>
                                <p>{this.state.booking.book_id}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>POL</label>
                                <p>{this.state.booking.pol.port_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Handover Location (FPD)</label>
                                <p>{this.state.booking.fpd.port_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Shipping Line</label>
                                <p>{this.state.booking.ship_line.ship_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Vessel Name / Voyage No</label>
                                <p>{this.state.booking.cro_details.vessel} / {this.state.booking.cro_details.voyage}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Cargo Type</label>
                                <p>{(this.state.booking.cargo_type === 1) ? 'FCL' : 'LCL'}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Container Type</label>
                                <p>{containerTypes[this.state.booking.commodity.container_type_id]}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Container Size</label>
                                <p> {(this.state.selectedContainers.container_size === "40hc") ? (this.state.booking.container_type_id === REFRIGERATED ? '40 RF HC' : '40 HC') : this.state.selectedContainers.container_size + (this.state.booking.container_type_id === REFRIGERATED ? ' RF' : ' FT')}</p>
                            </div>
                        </div>

                        {this.state.booking && this.state.booking.shipper ?
                            <div className="col-lg-3 col-sm-3">
                                <div className="static-data-input">
                                    <label>Shipper</label>
                                    <p>{this.state.booking.shipper.company_name}</p>
                                </div>
                            </div> : null
                        }

                    </div>
                </div>
                <div className="vgmviewlist">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Authorized Person name</label>
                                <p>{this.state.selectedContainers.authorised_person_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Shipper Registration/License No.</label>
                                <p>{this.state.booking.shipper_reg_no}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Designation of shipper official</label>
                                <p>{this.state.selectedContainers.authorised_person_designation}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Contact of authorised shipper official</label>
                                <p>{this.state.selectedContainers.authorised_person_contact}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>VGM Evaluation Method</label>
                                <p>{this.state.selectedContainers.vgm_method === 1 ? "Method 1" : "Method 2"}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Container no.</label>
                                <p>{this.state.selectedContainers.container_number}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>No. of packages</label>
                                <p>{this.state.selectedContainers.no_of_pkgs}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Package Type</label>
                                <p>{this.state.selectedContainers.package.name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>CSC Plate Max. Wt. Limit(Gross Weight)</label>
                                <p>{this.state.selectedContainers.max_weight_limit} Kgs</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>CRO No/ Booking No</label>
                                <p>{this.state.booking.cro_no}</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Cargo Wt(Total of cargo, packing dunnage)</label>
                                <p>{this.state.selectedContainers.cargo_weight} Kgs</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Tare Wt</label>
                                <p>{this.state.selectedContainers.tare_weight} Kgs</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Verified gross mass of container</label>
                                <p>{this.state.selectedContainers.verified_gross_mass} Kgs</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Weighbridge registration no.</label>
                                <p>{this.state.selectedContainers.weighbridge_reg_no}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Date and time of Weighing</label>
                                <p>{this.state.selectedContainers.vgm_done_at}</p></div>
                        </div>

                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Weighbridge Name</label>
                                <p>{this.state.selectedContainers.weighbridge_name}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Weighing slip no.</label>
                                <p>{this.state.selectedContainers.weighbridge_slip_no}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-3">
                            <div className="static-data-input">
                                <label>Weighbridge Address</label>
                                <p>{this.state.selectedContainers.weighbridge_address_1 + " " + this.state.selectedContainers.weighbridge_address_2 + "" + this.state.selectedContainers.weighbridge_address_3}</p>
                            </div>
                        </div>
                        {
                            this.state.booking.is_haz ?
                                <Fragment>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>UN No</label>
                                            <p>{this.state.selectedContainers.un_no}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>IMO class</label>
                                            <p>{this.state.selectedContainers.dg_class}</p></div>
                                    </div>
                                </Fragment> : ""}
                    </div>
                </div>
            </div>) : ""
    }
}

export default VgmView;