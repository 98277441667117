import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    UPDATE_USER,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    USER_LOGIN_REQUIRED,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    SHOW_AUTH_MODAL,
    HIDE_AUTH_MODAL
} from '../../constants/actionTypes';

export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: {user, history}
});
export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});
export const loginUserFailure = (message) => ({
    type: LOGIN_USER_FAILURE,
    payload: message
});
export const updateUser = (user) => ({
    type: UPDATE_USER,
    payload: user
});
export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: {user, history}
});
export const registerUserSuccess = (message) => ({
    type: REGISTER_USER_SUCCESS,
    payload: message
});
export const registerUserFailure = (error) => ({
    type: REGISTER_USER_FAILURE,
    payload: error
});
export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: {history}
});
export const logoutUserSuccess = (history) => ({
    type: LOGOUT_USER_SUCCESS,
    payload: {history}
});

export const loginRequired = (message) => ({
    type: USER_LOGIN_REQUIRED,
    payload: message
});

export const showAuthModal = (type) => ({
    type: SHOW_AUTH_MODAL,
    payload: type
});

export const hideAuthModal = () => ({
    type: HIDE_AUTH_MODAL
});