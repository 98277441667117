import React, {Component} from 'react';
import {Popover, PopoverBody} from "reactstrap";
import {generateQueryUrl} from "../../util/util.service";
import bookingicon2 from "../../assets/images/booking-icon2.png";
import shipLineIcon from "../../assets/images/shipline-icon.png";
import hazardousIcon from "../../assets/images/pending-icon.png";
import {REFRIGERATED} from "../../constants/containerTypes";
import moment from "moment";

class ResultRow extends Component {
    state = {};

    componentDidMount() {
        this.setState({['popoverHazardous_' + this.props.row_id]: false});
    };

    handleViewDeal = () => {
        let queryParams = {
            pol: this.props.pol_port.un_code,
            pod: this.props.pod_port.un_code,
            container_type: this.props.commodity_type.container_type_id,
            commodity: this.props.commodity_type.value,
            containers: this.props.container_20ft + ',' + this.props.container_40ft + ',' + this.props.container_40hc,
            date: this.props.route.etd_pol.format('YYYY-MM-DD'),
            route: this.props.route.route_id,
            readiness_date:moment(this.props.etd_pol).format('YYYY-MM-DD')
        };
        const viewDealQuery = generateQueryUrl(queryParams);
        this.props.history.push('/view-deal' + viewDealQuery);
    };

    toggle = (state) => {
        let name = 'popoverHazardous_' + this.props.row_id;
        this.setState({[name]: state});
    };

    render() {
        return (
            //     <>
            //     {[
            //       {
            //         placement: "left",
            //         text: "Left"
            //       },

            //     ].map((popoverHazardous, i) => {
            //       return <PopoverItem key={i} item={popoverHazardous} id={i} />;
            //     })}
            //   </>

            <tr className="list-main">
                <td colSpan="5" className="main-td">
                    <table className="table table-in" cellSpacing="0" cellPadding="0">
                        <tbody>
                        <tr>
                            <td className="shiping-name">
                                <div className="images-fix-size">
                                    <img src={shipLineIcon} className="img-fluid" alt=""/>
                                </div>
                                <p> {this.props.route.carrier_name} </p>
                            </td>
                            <td>
                                <p>{this.props.route.transit_time}</p>
                                <span>Days</span>
                            </td>
                            <td>
                                <p>USD <b>{this.props.route.freight_charges_20}</b></p>
                                <span>Per Container<br/> Upto <b>{this.props.route.frt_20_wt_limit} tons</b></span>
                            </td>
                            {parseInt(this.props.container_type.value) !== REFRIGERATED ?
                                <td>
                                    <p>USD <b>{this.props.route.freight_charges_40}</b></p>
                                    <span>Per Container <br/>Upto <b>{this.props.route.frt_40_wt_limit} tons</b></span>
                                </td> : <td/>
                            }
                            <td>
                                <p>USD <b>{this.props.route.freight_charges_40hc}</b></p>
                                <span>Per Container<br/> Upto <b>{this.props.route.frt_40hc_wt_limit} tons</b></span>
                            </td>
                            <td rowSpan="2" className="border-left-book-now">
                                <p className="color-green">{/*USD <b>1851</b>*/} &nbsp;&nbsp;&nbsp;</p>
                                <button onClick={this.handleViewDeal}>Book Now</button>
                                {/*<a className="price-drop">Price Breakdown &nbsp;
                                    <i className="fa fa-angle-down"/>
                                </a>*/}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5">
                                <ul>
                                    <li>
                                        {this.props.route.pol.port_name}
                                    </li>
                                    <li>
                                        <img src={bookingicon2} className="img-fluid" alt=""/>
                                    </li>
                                    <li>
                                        {this.props.route.pod.port_name}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr className="bg-tr-change">
                            <td colSpan="3">
                                <ul className="border-right">
                                    <li>
                                        <p>Cut Off</p>
                                        <b>{this.props.route.cut_off_date.format('DD MMM Y, dddd')}</b>
                                    </li>
                                    <li>
                                        <p>Estimated Departure Date</p>
                                        <b>{this.props.route.etd_pol.format('DD MMM Y, dddd')}</b>
                                    </li>
                                </ul>
                            </td>
                            <td colSpan="2">
                                <ul>
                                    <li>
                                        <p>Estimated Arrival Date </p>
                                        <b>{this.props.route.eta_pod.format('DD MMM Y, dddd')}</b>
                                    </li>
                                </ul>
                            </td>
                            <td colSpan="2">
                                {(
                                    this.props.route.haz_classes &&
                                    this.props.route.haz_classes.length > 0 &&
                                    parseInt(this.props.container_type.value) !== parseInt(REFRIGERATED)
                                ) ?
                                    <ul className="icon-right" id={this.props.row_id}
                                        onMouseEnter={() => this.toggle(true)}
                                        onMouseLeave={() => this.toggle(false)}>

                                        {parseInt(this.props.container_type.value) !== REFRIGERATED ?
                                            <img id={'popoverHazardous_' + this.props.row_id}
                                                 src={hazardousIcon}
                                                 className="img-fluid haz" alt=""/> : null
                                        }

                                        <Popover className="container-box" id={this.props.row_id}
                                                 isOpen={this.state['popoverHazardous_' + this.props.row_id]}
                                                 target={"popoverHazardous_" + this.props.row_id}
                                                 toggle={() => this.toggle('popoverHazardous_' + this.props.row_id)}>
                                            <PopoverBody className="popoverHazardous">
                                                <p>
                                                    Hazardous cargo can be accepted for this port pair.
                                                    <br/>
                                                    Classes {[this.props.route.haz_classes].join(',')} are acceptable at
                                                    an additional hazardous charges
                                                    of
                                                    {this.props.route.freight_charges.haz_20 > 0 ? ' USD ' + this.props.route.freight_charges.haz_20 + '/20Ft,' : null}
                                                    {this.props.route.freight_charges.haz_40 > 0 ? ' USD ' + this.props.route.freight_charges.haz_40 + '/40Ft' : null}
                                                    {this.props.route.freight_charges.haz_40hc > 0 ? ' and USD ' + this.props.route.freight_charges.haz_40hc + '/40 HC' : null}
                                                </p>
                                            </PopoverBody>
                                        </Popover>
                                    </ul>
                                    : null}
                            </td>
                        </tr>
                        </tbody>

                    </table>
                </td>
            </tr>
        );
    }
}

export default ResultRow;