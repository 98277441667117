import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ENQUIRIES, REFRESH_ENQUIRIES} from "../../constants/actionTypes";
import Enquiry from "../../util/enquiry.service";
import {fetchEnquirySuccess} from "./actions";
import {NotificationManager} from "react-notifications";

const fetchEnquiryAsync = async () => {
    return await Enquiry.history()
        .then(response => response.data)
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
};

function* fetchEnquiryFromApi() {
    try {
        const enquiry_data = yield call(fetchEnquiryAsync);
        if (enquiry_data.status !== 'failed') {
            yield put(fetchEnquirySuccess(enquiry_data.data));
        }
    } catch (error) {

    }
}

export function* watchFetchEnquiries() {
    yield takeEvery(FETCH_ENQUIRIES, fetchEnquiryFromApi);
}

export function* watchRefreshEnquiries() {
    yield takeEvery(REFRESH_ENQUIRIES, fetchEnquiryFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchEnquiries),
        fork(watchRefreshEnquiries)
    ]);
}