import React, {Component, Fragment} from "react";
import {Button} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {fetchBanks} from "../../../redux/bank/actions";
import {connect} from "react-redux";
import {NotificationManager} from 'react-notifications';
import Bank from "../../../util/bank.service";
import BankModal from "../../../components/bank/BankModal";
import BankCard from "../../../components/bank/BankCard";

class BankDetails extends Component {

    state = {
        modalOpen: false,
        modalLoading: false,
        bank: [],
        action: true
    };

    componentDidMount() {
        this.props.fetchBanks();
    };


    editBank = (id) => {
        let bank = this.props.banks.list.find(bank => bank.id === id);
        this.setState({bank, modalOpen: !this.state.modalOpen, action: false});

    };

    toggleAddModal = () => {
        console.log(1111);
        this.setState({modalOpen: !this.state.modalOpen, action: true, bank: []});
        console.log(2222);
    };

    addBank = (data) => {
        if (this.state.modalLoading === false) {
            this.setState({modalLoading: true});
            Bank.add(data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        this.props.fetchBanks();
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        NotificationManager.warning('Warning message', response.data.message);
                    }
                })
                .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    updateBank = (data, id) => {
        this.setState({modalLoading: true});
        Bank.update(id, data)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.success('Success message', response.data.message);
                    this.props.fetchBanks();
                } else {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.warning('Warning message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    };

    removeBank = (id) => {
        if (window.confirm("Are you sure you want to delete this Bank Detail ?")) {
        Bank.delete(id)
            .then(response => {
                if (response.data.status === "success") {
                    this.props.fetchBanks();
                    NotificationManager.success('Success message', response.data.message);
                }
            })
            .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
        }
    };

    render() {
        return (
            <Fragment>
                <div className="add-line-bg">
                    <h1>Bank Details</h1>
                </div>
                {this.state.modalOpen ?
                    <BankModal
                        bankDetails={this.state.bank}
                        modalOpen={this.state.modalOpen}
                        action={this.state.action}
                        modalLoading={this.state.modalLoading}
                        toggleAddModal={this.toggleAddModal}
                        addBank={this.addBank}
                        updateBank={this.updateBank}/>
                    : ""}
                {!this.props.banks.loading ?
                    <div className="address-add-view">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="add-card" onClick={this.toggleAddModal}>
                                    <Button
                                        color="primary"
                                        size="md"
                                        className="top-right-button">
                                        <div className="add-btn-icon">
                                            <i className="fa fa-user-plus"/>
                                        </div>
                                        <span>Add New </span>
                                    </Button>
                                </div>
                            </div>
                            {this.props.banks.list.map((bank, key) =>
                                <BankCard key={key}
                                          bank={bank}
                                          editBank={this.editBank}
                                          removeBank={this.removeBank}/>
                            )}
                        </div>
                    </div>
                    :
                    <h2 className={"loading-text"}>Loading...</h2>
                }
            </Fragment>
        );
    }
}


const mapStateToProps = ({banks}) => {
    return {banks};
}


export default connect(mapStateToProps, {fetchBanks})(BankDetails);