import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import Auth from '../../util/auth.service';
import Storage from "../../util/storage.service";
import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    REDIRECT_URL
} from '../../constants/actionTypes';

import {
    loginUserSuccess,
    logoutUserSuccess,
    loginUserFailure,
    registerUserSuccess,
    registerUserFailure
} from './actions';

import {resetBookings} from "../bookings/actions";
import {NotificationManager} from "react-notifications";

const loginWithEmailPasswordAsync = async (email, password) => {
    return await Auth.login({email: email, password: password})
        .then(authUser => authUser)
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
};

function* loginWithEmailPassword({payload}) {
    const {email, password} = payload.user;
    const {history} = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (loginUser.data.status !== 'failed') {

            Auth.token = loginUser.data.data.token;
            Auth.user = loginUser.data.data.user;

            yield put(loginUserSuccess(loginUser.data.data.user));
            let redirect_to = Storage.getFlash(REDIRECT_URL);
            if (redirect_to) {
                history.push(redirect_to);
            }else{
                history.push("/booking/history");
            }
        } else {
            // catch throw
            yield put(loginUserFailure(loginUser.data.message));
            // console.log('login failed :', loginUser.data.message);
        }
    } catch (error) {
        // catch throw
        yield put(loginUserFailure('Something went wrong.'));
        // console.log('login error : ', error);
    }
}

const registerUserDetailsAsync = async (userDetails) => {
    return await Auth.register(userDetails)
        .then(authUser => authUser)
        .catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
};

function* registerUserDetails({payload}) {
    const {history, userDetails} = payload;
    try {
        const registeredUser = yield call(registerUserDetailsAsync, userDetails);
        if (registeredUser.data.status !== 'failed') {
            yield put(registerUserSuccess(registeredUser.data.message));
        } else {
            yield put(registerUserFailure(registeredUser.data.message));
        }
    } catch (error) {
        yield put(registerUserFailure('Something went wrong.'));
    }
}

/* const logoutAsync = async (history) => {
    const reuqest = await Auth.logout().then(authUser => authUser).catch(error => {
            NotificationManager.error('Error found');
            console.log(error);
        });
    return reuqest;
}; */

function* logout({payload}) {
    const {history} = payload;
    try {
        Auth.logout();
        // yield call(logoutAsync, history);
        yield put(logoutUserSuccess(history));
        yield put(resetBookings());
        history.push('/');
    } catch (error) {
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerUserDetails);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser)
    ]);
}