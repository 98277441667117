import Request from "./api.service";

export default class Enquiry {

    static history() {
        return Request.get(`${Request.base_url}user/enquiry`);
    }

    static details(id) {
        return Request.get(`${Request.base_url}user/enquiry/${id}`);
    }

    static user_approval(id, data) {
        data = {...data, '_method': 'PUT'};
        return Request.post(`${Request.base_url}user/enquiry/${id}`, data);
    }
}