import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import {Label, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {loginUser, loginUserFailure} from "../../../redux/auth/actions";
import {connect} from "react-redux";
import ButtonLoader from "../../../util/ButtonLoader";
import { isEmptyNullUndefined } from '../../../util/util.service';

class Login extends Component {

    state = {
        isShowPassword: false
    };

    componentDidMount() {

    }

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            this.props.loginUser(values, this.props.history);
        }
    };
    togglePassword = () => {
        this.setState({isShowPassword: !this.state.isShowPassword})
    }

    render() {

        return (
          <Fragment>
            {!isEmptyNullUndefined(this.props.auth.error_message) && (
              <div className="auth-error">
                &nbsp;&nbsp; {this.props.auth.error_message} &nbsp;&nbsp;
              </div>
            )}

            <AvForm onSubmit={this.onSubmit}>
              <AvGroup className="error-t-negative">
                <Label>Email</Label>
                <AvField
                  name="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChange}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter Email Id",
                    },
                  }}
                />
              </AvGroup>

                <AvGroup className="error-t-negative password-div">
                    <div>
                        <Label>Password</Label>
                        <AvField
                            name="password"
                            type={this.state.isShowPassword ? "text" : "password"}
                            value={this.state.password}
                            onChange={this.handleChange}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Please enter Password",
                                },
                            }}
                        />
                    </div>
                    <i className={this.state.isShowPassword ? "fa fa-eye password-eye" : "fa fa-eye-slash password-eye"} onClick={()=>this.togglePassword()}/>
                </AvGroup>

                <div className="forget-login-psss">
                <NavLink to={`/forgot-password`}>Forgot Password</NavLink>
              </div>
              <div className="login-btn">
                <Button color="primary">
                  <ButtonLoader
                    isLoading={this.props.auth.loading}
                    label="Login"
                  />
                </Button>
              </div>
            </AvForm>
          </Fragment>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUser, loginUserFailure}
)(Login);

