import React, {Component, Fragment} from "react";
import {CustomInput} from "reactstrap";
import {slideDown, slideUp} from "../../routes/desktop/bl/anim";
import collect from "collect.js";
import {AvField, AvGroup} from "availity-reactstrap-validation";
import {REFRIGERATED} from "../../constants/containerTypes";

class ContainerFields extends Component {

    state = {
        expanded: false
    }

    componentDidMount() {
        console.log(this.props.container);
    }

    toggleExpander = (e) => {
        if (!this.state.expanded) {
            this.setState(
                {expanded: true},
                () => {
                    if (this.refs.expanderBody) {
                        slideDown(this.refs.expanderBody);
                    }
                }
            );
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => {
                    this.setState({expanded: false});
                }
            });
        }
    }

    containerSelectHandler = (event, container_id) => {
        this.props.containerSelectHandler(event, container_id);
        this.toggleExpander(event);
    }

    render() {
        let container_size = (this.props.container.container_size === "40hc" ? '45' : this.props.container.container_size);
        let isoCodeList = [];
        if (this.props.isoCodeList) {
            isoCodeList = collect(this.props.isoCodeList);
            isoCodeList = isoCodeList.where('container_size', container_size);
            isoCodeList = isoCodeList.toArray();
        }
        return (
            <Fragment>
                <tr className="container-main-tr" key="main">
                    <td>
                        <CustomInput
                            type="checkbox"
                            id={"container_" + this.props.index}
                            onChange={(event) => this.containerSelectHandler(event, this.props.container.id)}
                            checked={this.props.containerSelected.includes(this.props.container.id)}/>
                    </td>
                    <td>{this.props.container.container_number}</td>
                    <td>{(this.props.container.container_size === "40hc" ? "40" : this.props.container.container_size) + (parseInt(this.props.container.container_type) === REFRIGERATED && this.props.container.container_size === "40hc" ? "RF HC" : (parseInt(this.props.container.container_type) === REFRIGERATED ? "RF" : (this.props.container.container_size === "40hc" ? "HC" : "FT")))}</td>

                </tr>
                {this.state.expanded && (
                    <tr className="expandable" key="tr-expander">
                        <td className="uk-background-muted" colSpan={6}>
                            <div ref="expanderBody" className="inner uk-grid">
                                <div className="uk-width-1-4 uk-text-center">
                                    <div className="row">
                                        <div className="col-lg-3 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>SB No. or Inv No.</label>
                                                <AvField name={'invoice_no_' + this.props.container.id} type="text"
                                                         className="invoice_no"
                                                         customprop="invoice_no"
                                                         id={'invoice_no_' + this.props.container.id}
                                                         value={(this.props.container) ? this.props.container.invoice_no : null}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter SB No. or Inv No.'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-3 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Custom Seal</label>
                                                <AvField name={'customs_seal_' + this.props.container.id} type="text"
                                                         className="customs_seal"
                                                         customprop="customs_seal"
                                                         id={'customs_seal_' + this.props.container.id}
                                                         value={(this.props.container) ? this.props.container.customs_seal : null}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Custom Seal'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-3 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>Agent Seal</label>
                                                <AvField name={'agent_seal_' + this.props.container.id} type="text"
                                                         className="agent_seal"
                                                         customprop="agent_seal"
                                                         id={'agent_seal_' + this.props.container.id}
                                                         value={(this.props.container) ? this.props.container.agent_seal : null}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Agent Seal'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="col-lg-3 col-12">
                                            <AvGroup className="error-t-negative">
                                                <label>ISO Code</label>
                                                <AvField name={'iso_code_id_' + this.props.container.id} type="select"
                                                         id={"iso_code_id_" + this.props.container.id}
                                                         value={(this.props.container) ? this.props.container.iso_code_id : null}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please Select Iso Code'
                                                             }
                                                         }}>
                                                    <option value="">Select ISO Code</option>
                                                    {isoCodeList.map(iso_code =>
                                                        <option value={iso_code.id}>{iso_code.description}</option>
                                                    )}
                                                </AvField>
                                            </AvGroup>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
                )
                }
            </Fragment>
        )
    }

}

export default ContainerFields;