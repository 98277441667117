import {FETCH_MEMBER_SUCCESS, FETCH_MEMBERS} from "../../constants/actionTypes";

export const fetchMembers = () => ({
    type: FETCH_MEMBERS,
    payload: []
});

export const fetchMemberSuccess = (member) => ({
    type: FETCH_MEMBER_SUCCESS,
    payload: member
});

