import React, {Component, Fragment} from "react";

class ProductRow extends Component {
    state = {

    }

    render() {
        return (
            <Fragment>
                <tr>
                    <td>
                       {/* <AvGroup className="error-t-negative">
                            <AvField name={'hs_code' + this.props.container.id}
                                     id={'customs_seal_' + this.props.container.id}
                                     type="text"
                                     value=""
                            />
                        </AvGroup>*/}
                    </td>
                </tr>

            </Fragment>
        );
    }
}

export default ProductRow;