import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Route, Switch} from 'react-router-dom';
import {isMobile} from "../util/util.service";
import DesktopRoutes from '../routes/desktop';
import MobileRoutes from '../routes/mobile';
import Header from "./Header";
import Footer from "./Footer";
import {logoutUser} from "../redux/auth/actions";

class App extends Component {
    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <Header/>
                <Switch>
                    {isMobile() ?
                        <Route path={`${match.url}`} component={MobileRoutes}/> :
                        <Route path={`${match.url}`} component={DesktopRoutes}/>}
                    {/*<Route path={`${match.url}`} component={DesktopRoutes}/>*/}
                </Switch>
                {/* Do not render footer on home page */}
                {!match.isExact ? <Footer/> : ''}
            </Fragment>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {logoutUser}
)(App);